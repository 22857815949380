import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FaAngleDown } from 'react-icons/fa';
import BottomDrawer from '../../components/template/bottomdrawer';
import CustomHookModal from './editquery-modal';
import GreyClose from '../../assets/icons/grey-close.svg';
import { cloneDeep } from 'lodash';
import { RowAction } from '../cutsmart/common';
const BotInputPrinter = ({
  bottype,
  printernameFocus,
  setPrinterNameFocus,
  printernameError,
  setPrinterNameError,
  selPrefFormat,
  setSelPrefFormat,
  form,
  setForm,
  onChange,
  errors,
  isMobile,
  isTab,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const Class_RECT = 'rectangle-input';
  const Class_RECT_Highlight = 'rectangle-input highlight';
  const Class_RECT_Error = 'rectangle-input error';
  const [showHookModal, setShowHookModal] = useState(false);
  const [hookValue, setHookValue] = useState('');
  const closeCallback = setShowHookModal => {
    setShowHookModal(false);
  };

  return (
    <div
      className='bg-white'
      style={{
        alignContent: 'left',
        marginLeft: '40px',
      }}
    >
      {isMobile && showHookModal ? (
        <BottomDrawer
          entity={''}
          drawerTitle=''
          handleClose={() => closeCallback(setShowHookModal)}
          popUp={showHookModal && isMobile}
          drawerBody={
            <CustomHookModal
              showModal={showHookModal}
              closeCallback={() => closeCallback(setShowHookModal)}
              isMobile={isMobile}
              isTab={isTab}
              hookValue={hookValue}
              setHookValue={setHookValue}
              form={form}
            />
          }
        />
      ) : (
        showHookModal && (
          <CustomHookModal
            showModal={showHookModal}
            closeCallback={() => closeCallback(setShowHookModal)}
            isMobile={isMobile}
            isTab={isTab}
            hookValue={hookValue}
            setHookValue={setHookValue}
            form={form}
          />
        )
      )}

      <div style={{ height: '27px' }}></div>
      <Form style={{ padding: '0px' }}>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='row m-0 p-0'>
            <div className='col-md-10 col-sm-10 col-10 m-0 p-0'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                BOT TYPE
              </label>

              <Form.Control
                id='bottype'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='bottype'
                placeholder='Enter Value Here'
                value={bottype === 'SCAN_CLIENT' ? 'PRINTER' : ''}
                disabled
                isInvalid={!!errors.bottype}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
            </div>
          </div>
        </div>
        <div
          className={
            printernameError
              ? Class_RECT_Error
              : printernameFocus
                ? Class_RECT_Highlight
                : Class_RECT
          }
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='row m-0 p-0'>
            <div className='col-md-10 col-sm-10 col-10 m-0 p-0'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                PRINTER NAME
              </label>

              <Form.Control
                id='name'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='name'
                placeholder='Enter Value Here'
                value={form.name}
                isInvalid={!!errors.name}
                onFocus={() => {
                  setPrinterNameFocus(printernameFocus => true);
                  setPrinterNameError(printernameError => false);
                  errors.name = '';
                }}
                onBlur={() => {
                  setPrinterNameFocus(printernameFocus => false);
                }}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
              {errors.name && (
                <Form.Control.Feedback type='invalid'>
                  <div style={{ height: '8px' }}></div>
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </div>
            <div
              className='col-md-2 col-sm-2 col-2 password-image'
              style={{ paddingTop: '15px', textAlignLast: 'right' }}
            >
              <img src={GreyClose} alt=''></img>
            </div>
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Enable Trace
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enabletrace'
              name='enabletrace'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enabletrace = !newVal.enabletrace;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enabletrace === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Enable Curl
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enablecurl'
              name='enablecurl'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enablecurl = !newVal.enablecurl;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enablecurl === true}
            />
          </div>
        </div>
        <div
          className={'row ' + Class_RECT}
          style={{ margin: '0px', padding: '0px', marginBottom: '20px' }}
        >
          <div className='col-10'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '6px' }}
            >
              PREFERRED FORMAT
            </label>

            <Form.Control
              id='pref_format'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='pref_format'
              placeholder='Select a Value'
              value={selPrefFormat ? selPrefFormat : form.pref_format}
              isInvalid={!!errors.pref_format}
              onChange={onChange}
              style={{ marginLeft: '6px' }}
            />
          </div>
          <div className='col-2' style={{ paddingTop: '20px' }}>
            <RowAction
              actionLabel={<FaAngleDown />}
              actions={[
                {
                  key: '0',
                  label: 'PNG',
                  onClick: () => {
                    setSelPrefFormat('PNG');
                  },
                },
                {
                  key: '1',
                  label: 'JPG',
                  onClick: () => {
                    setSelPrefFormat('JPG');
                  },
                },
              ]}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Print Exe Path
            </label>

            <Form.Control
              id='print_exe_path'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='print_exe_path'
              placeholder='Enter Value Here'
              value={form.print_exe_path}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Printer Atrgument
            </label>

            <Form.Control
              id='printer_argument'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='printer_argument'
              placeholder='Enter Value Here'
              value={form.printer_argument}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BotInputPrinter;
