import React, { useRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Menu from '../../../assets/icons/icon-menu.svg';
import Back from '../../../assets/icons/backBtn.svg';
import ShipmentIcon from '../../../assets/icons/icon-shipment.svg';
import { toggleSideMenu } from '../../../store/app/nav/actions';
import useParentWidth from '../../../hooks/useParentWidth';
import OnlyMobile from '../../../components/common/only-mobile';

import { OrgLogo } from '../../../components/org/org-logo';
import { StationSelector } from '../common';
//const CUT_GUIDE_HOME = '/app/cutguide/home';
/* eslint-disable sonarjs/cognitive-complexity */

const PatternHeader = ({
  currentStation,
  onClickMenu,
  history,
  location,
  onBack,
  label,
  onPrintAllCb,
}) => {
  const dispatch = useDispatch();

  const container = useRef();

  // eslint-disable-next-line
  const [scrollY, setScrollY] = useState(0);

  const handleSideMenuToggle = useCallback(() => {
    dispatch(toggleSideMenu());
  }, [dispatch]);

  const onClick = useCallback(() => {
    if (onClickMenu) {
      onClickMenu();
    } else {
      history.push('/app/cutguide/home');
    }
  }, [history, onClickMenu]);

  useParentWidth(container);

  useEffect(() => {
    const onScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [setScrollY]);

  return (
    <div
      ref={container}
      className={`flex-row header navbar-expand-lg d-flex p-3 py-4 pt-3 `}
      style={{ zIndex: 10 }}
    >
      {!onBack && (
        <button
          className='p-0 border-0 '
          style={{ backgroundColor: 'transparent' }}
          data-toggle='offcanvas'
          onClick={handleSideMenuToggle}
          type='button'
        >
          <img src={Menu} alt='Menu' />
        </button>
      )}
      {onBack && (
        <button
          className='p-0 border-0 '
          style={{ backgroundColor: 'transparent', minWidth: '24px' }}
          data-toggle='offcanvas'
          onClick={onBack}
          type='button'
        >
          <img src={Back} alt='Back' />
        </button>
      )}
      <div
        className='page-header cursor-pointer d-flex align-items-center'
        onClick={onClick}
      >
        <div className='page-title d-flex align-items-center'>
          <OnlyMobile>
            <img src={ShipmentIcon} alt='' className='ps-2' />
          </OnlyMobile>
          <div className='ps-2 d-flex align-items-center'>
            {label
              ? label
              : currentStation
                ? currentStation.station_name
                : 'Cut Guide'}
          </div>
          <div className='ps-2 d-flex align-items-center'>
            <StationSelector history={history} location={location} />
          </div>
        </div>
      </div>
      <div className='ms-auto d-flex justify-content-end'>
        <div className='pe-3 d-flex align-items-center'>
          <div
            className='btn btn-light btn-link '
            style={{ padding: '8px !important' }}
            onClick={onPrintAllCb}
          >
            <div className='d-flex'>
              <div className=''>Print All Labels</div>
            </div>
          </div>
        </div>

        <div
          className='brang-logo ms-auto cursor-pointer d-flex align-items-center'
          onClick={onClick}
        >
          <OrgLogo />
        </div>
      </div>
    </div>
  );
};

PatternHeader.prototype = {
  onClickMenu: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  pipelineMode: PropTypes.bool,
  pipeline_type: PropTypes.string,
  scanState: PropTypes.string,
};

export default PatternHeader;
