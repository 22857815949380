import React, { useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import '../../scss/components/_dropdown.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ScreenLoader from '../../components/common/screen-loader';
import useMobile from '../../hooks/useMobile';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import {
  createRules,
  getRules,
  createRuleValue,
  deleteRule,
  getRuleValue,
} from '../../services/workrule';
import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import WorkFlowHeader from './workflowheader';
import Select from '../../components/operations/select-upload';
import BottomDrawerWorkstations from './bottom-drawer-workstations';
import { DeleteIcon } from '../../assets/icons';
import BottomDrawerContent from './bottom-drawer-menu';
import { cloneDeep } from 'lodash';
import { FaAngleDown } from 'react-icons/fa';
import { getLinkOperation } from '../../services/station';
import { ItemMenu } from './item-menu';
import { ActionConfirmModal } from './action-confirm';
import { getItemList } from '../../services/items';
const Class_RECT = 'rectangle-input';
const Class_RECT_Highlight = 'rectangle-input highlight';
const Class_RECT_Error = 'rectangle-input error';
const SB = 'space-between';

const OperationsFilter = ({
  labelText,
  operationList,
  setOperationList,
  operationCode,
  setOperationCode,
  setOperationFocus,
  setOperationError,
}) => {
  if (!operationCode || operationCode === null) setOperationFocus(true);
  else {
    setOperationFocus(false);
    setOperationError(false);
  }
  const operationFilter = useCallback(term => {
    return new Promise((resolve, reject) => {
      const operationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) operationBody.req_payload.term = term.toUpperCase();
      getLinkOperation(operationBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  }, []);

  return (
    <Select
      label={labelText}
      getOptions={operationFilter}
      labelField='name'
      valueField='uuid'
      onSelect={i => {
        const list = cloneDeep(operationList);
        const objWS = {
          deleted: false,
          operation: {
            name: i.operation_code,
            uuid: i.uuid,
          },
        };
        list.push(objWS);
        setOperationList(list);
        setOperationCode(objWS);
      }}
      search
    />
  );
};

const AddRow = ({
  rowValue,
  index,
  onUpdateElement,
  operationFetchList,
  operationCode,
}) => {
  const [focus] = useState({});
  //setFocus({});
  const onDependentUpdate = useCallback(
    key => {
      const rv = cloneDeep(rowValue);
      rv.dependent_code = key;
      onUpdateElement(rv, index);
    },
    [onUpdateElement, index, rowValue],
  );

  const [dependentList, setDependentList] = useState([]);
  useEffect(() => {
    const newElement = {
      key: 'RELEASE',
      label: 'RELEASE',
      onClick: () => onDependentUpdate('RELEASE'),
    };

    const updatedList = [
      newElement,
      ...operationFetchList.map(val => ({
        key: val.operation_code,
        label: val.name,
        onClick: () => onDependentUpdate(val.operation_code),
      })),
    ];

    setDependentList(updatedList);
  }, [onDependentUpdate, setDependentList, operationFetchList]);

  return (
    <div className='row' style={{ paddingTop: '10px', width: '950px' }}>
      <div
        className='col-1'
        style={{ alignSelf: 'center', textAlignLast: 'right' }}
      >
        {index + 1}
      </div>

      <div className='col-4'>
        <div
          className={
            rowValue.err
              ? Class_RECT_Error
              : focus?.dependent_code
                ? Class_RECT_Highlight
                : Class_RECT
          }
          style={{ width: '230px' }}
        >
          <div>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              DEPENDENT OPERATION CODE
            </label>

            <div
              style={{
                display: 'flex',
                color: '#006EED',

                cursor: 'pointer',
              }}
            >
              <div>
                <ItemMenu
                  actionLabel={
                    <div
                      style={{
                        display: 'flex',
                        paddingLeft: '10px',
                      }}
                    >
                      <Form.Control
                        id={'dependent' + index}
                        style={{ color: '#006EED' }}
                        type='text'
                        autoComplete='off'
                        name={'dependent' + index}
                        value={
                          rowValue.dependent_code
                            ? rowValue.dependent_code
                            : 'Select Dependent Code'
                        }
                      />
                      <div style={{ width: '30px' }}></div>
                      <FaAngleDown />
                    </div>
                  }
                  actions={rowValue.uuid ? [] : dependentList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-2'>
        <div>Mandatory</div>
        <div style={{ height: '10px' }}></div>
        <Form.Check
          type='switch'
          style={{ alignItem: 'center' }}
          id={'mandatory' + index}
          name={'mandatory' + index}
          onChange={() => {
            const rv = cloneDeep(rowValue);
            rv.mandatory = !rv.mandatory;
            onUpdateElement(rv, index);
          }}
          value={rowValue.mandatory}
          checked={rowValue.mandatory}
        />
      </div>
      <div className='col-4' style={{ paddingLeft: '40px' }}>
        <div>Paired</div>
        <div style={{ height: '10px' }}></div>
        <Form.Check
          type='switch'
          style={{ alignItem: 'center' }}
          id={'paired' + index}
          name={'paired' + index}
          onChange={() => {
            const rv = cloneDeep(rowValue);
            rv.paired = !rv.paired;
            onUpdateElement(rv, index);
          }}
          value={true}
          checked={rowValue.paired}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const NewWorkflow = () => {
  const location = useLocation();
  const [operationError, setOperationError] = useState(false);
  const [operationFocus, setOperationFocus] = useState(false);
  const [itemError, setItemError] = useState(false);
  const [itemFocus, setItemFocus] = useState(false);
  const [operationCode, setOperationCode] = useState();
  const [itemCode, setItemCode] = useState();
  const [rulesList, setRulesList] = useState();
  const [operationList, setOperationList] = useState([]);
  const [operationFetchList, setOperationFetchList] = useState([]);
  const [rulesValues, setRuleValues] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isTab] = useMobile();
  const [ruleUuid, setRuleUuid] = useState('');
  const [workstationOpen, setWorkstationOpen] = useState(false);
  const [titleText, setTitleText] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [itemList, setItemList] = useState([]);
  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const _params = Object.fromEntries(urlSearchParams.entries());
    setRuleUuid(_params.uuid);
    // setParams(_params);
  }, [location, setRuleUuid]);

  // const itemList = [
  //   {
  //     key: 'PART',
  //     label: 'PART',
  //     onClick: () => setItemCode('PART'),
  //   },
  //   {
  //     key: 'HARDWARE',
  //     label: 'HARDWARE',
  //     onClick: () => setItemCode('HARDWARE'),
  //   },
  //   {
  //     key: 'BOARD',
  //     label: 'BOARD',
  //     onClick: () => setItemCode('BOARD'),
  //   },
  // ];
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const itemResp = await getItemList(
          {
            latest: [true],
            deleted: [false],
            active: [true],
            approved: [true],
            parent_item_code: ['GOODS'],
          },
          0,
          50,
        );
        if (itemResp && itemResp.payload) {
          const x = itemResp.payload.map(i => ({
            key: i.code,
            label: i.code,
            onClick: () => setItemCode(i.code),
          }));
          setItemList(x);
        }
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };
    fetchItems();
  }, []);
  const FetchOperation = useCallback(
    async oid => {
      const req = {
        approved: true,
        active: true,
        deleted: false,
        page: 0,
        size: 100,
        sort: [{ column: 'operation_code', asc: true }],
        req_payload: {
          deleted: [false],
          active: [true],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_OPERATION'));
        const fetchOp = await getLinkOperation(req);
        if (fetchOp && fetchOp.status !== 200) {
          return;
        }
        setOperationFetchList(fetchOp.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_OPERATION'));
      }
    },
    [dispatch],
  );
  useEffect(() => {
    FetchOperation();
  }, [FetchOperation]);

  const fetchRules = useCallback(
    async rid => {
      if (ruleUuid) {
        const rulesBody = {
          req_payload: {
            uuid: [ruleUuid],
            deleted: [false],
          },
        };
        try {
          dispatch(actions.busy.add('FETCH_RULES'));
          const getRule = await getRules(rulesBody);

          if (getRule && getRule.status !== 200) {
            return;
          }

          setRulesList(rulesList => getRule.payload);
          const objWS = {
            deleted: false,
            operation: {
              name: getRule.payload[0].operation_code,
            },
          };
          setOperationCode(objWS);
        } finally {
          dispatch(actions.busy.remove('FETCH_RULES'));
        }
      }
    },
    [dispatch, ruleUuid],
  );
  useEffect(() => {
    fetchRules();
  }, [fetchRules]);

  const fetchRuleValue = useCallback(
    async rid => {
      if (rulesList && rulesList.length > 0 && rulesList[0].uuid) {
        const rulesValueBody = {
          req_payload: {
            rule_id: [rulesList[0].uuid],
            deleted: [false],
          },
        };
        try {
          dispatch(actions.busy.add('FETCH_RULES'));
          const getRValue = await getRuleValue(rulesValueBody);

          if (getRValue && getRValue.status !== 200) {
            return;
          }

          if (getRValue && getRValue.status !== 200) {
            return;
          }
          setRuleValues(
            getRValue.payload.map(val => {
              val._paired = val.paired;
              val._mandatory = val.mandatory;
              val._deleted = val.deleted;
              return val;
            }),
          );
        } finally {
          dispatch(actions.busy.remove('FETCH_RULES'));
        }
      }
    },
    [dispatch, rulesList],
  );
  useEffect(() => {
    if (rulesList && rulesList.length > 0) fetchRuleValue();
  }, [fetchRuleValue, rulesList]);

  const delRule = useCallback(
    async rid => {
      try {
        dispatch(actions.busy.add('DELETE_RULES'));
        const deleteR = await deleteRule(rid);
        if (deleteR && deleteR.status !== 200) {
          return toastError('Unable to Delete Rule Value');
        }
      } finally {
        dispatch(actions.busy.remove('DELETE_RULES'));
        history.goBack();
      }
    },
    [dispatch, history],
  );
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const saveChanges = useCallback(async () => {
    if (!operationCode || operationCode === null) {
      setOperationError(true);
      toastError('Please Select Operation');
      return;
    }
    const _itemCode = itemCode || (rulesList && rulesList[0]?.item_code);
    if (!_itemCode || _itemCode === null || _itemCode === '') {
      setItemError(true);
      toastError('Please Select Item');
      return;
    }
    try {
      dispatch(actions.busy.add('CREATE_RULE'));

      for (let i = 0; i < rulesValues.length; i++) {
        const rv = rulesValues[`${i}`];
        if (
          rv.dependent_code === rv._dependent_code &&
          rv.mandatory === rv._mandatory &&
          rv.paired === rv._paired &&
          rv.uuid
        ) {
          continue;
        }
        let oCode =
          rulesList && rulesList.length > 0 && rulesList[0].operation_code
            ? rulesList[0].operation_code
            : operationCode
              ? operationCode.operation.name
              : '';
        let nameVal =
          oCode +
          '-' +
          (itemCode || rulesValues[`${i}`].item || rulesList[0].item_code);
        const data = {
          deleted: false,
          active: true,
          approved: true,
          req_payload: {
            item_code: _itemCode,
            operation_code: oCode,
            name: nameVal,
          },
        };
        if (rulesList && rulesList.length > 0 && rulesList[0].uuid) {
          data.req_payload.uuid = rulesList[0].uuid;
        }
        const createR = await createRules(data);
        if (createR.status !== 200) {
          toastError(
            createR.err && createR.err.err
              ? createR.err.err
              : 'Unable to create the Rule',
          );
          return;
        }
        const datavalue = {
          deleted: false,
          active: true,
          approved: true,
          req_payload: {
            rule_id: createR.payload.uuid,
            dependent_code: rv.dependent_code,
            mandatory: rv.mandatory || false,
            paired: rv.paired || false,
          },
        };
        if (rulesValues && rulesValues.length > 0 && rulesValues[`${i}`].uuid) {
          datavalue.req_payload.uuid = rulesValues[`${i}`].uuid;
        }
        if (
          rulesValues &&
          rulesValues.length > 0 &&
          rulesValues[`${i}`].deleted
        ) {
          datavalue.deleted = rulesValues[`${i}`].deleted;
        }
        const createRV = await createRuleValue(datavalue);
        if (createRV.status !== 200) {
          toastError(
            createRV.err && createRV.err.err
              ? createRV.err.err
              : 'Unable to create the Rule Value',
          );
          return;
        }
      }
      toastMessage({
        type: 'success',
        message: 'Created Successfully',
      });
      history.goBack();
    } finally {
      dispatch(actions.busy.remove('CREATE_RULE'));
    }
  }, [dispatch, rulesList, operationCode, itemCode, rulesValues, history]);

  const addRule = useCallback(
    e => {
      const aR = cloneDeep(rulesValues);
      const ruleval = {
        item: itemCode ? itemCode : '',
        dependent_code: '',
        mandatory: false,
        paired: false,
        rule: { item_code: itemCode ? itemCode : '' },
        deleted: false,
      };
      aR.push(ruleval);
      setRuleValues(aR);
    },
    [rulesValues, setRuleValues, itemCode],
  );
  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader
          force={
            (ruleUuid &&
              rulesList &&
              rulesList.length > 0 &&
              rulesList[0].item_code &&
              rulesList[0].operation_code) ||
            !ruleUuid
              ? false
              : true
          }
        />
      </div>
      <ToastContainer />
      {showConfirm && (
        <ActionConfirmModal
          closeCb={() => setShowConfirm(false)}
          confirmCb={() => delRule(ruleUuid)}
        />
      )}
      <BottomDrawerContent
        isMobile={isMobile}
        isTab={isTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        title={titleText}
        id={ruleUuid}
      />
      <div>
        <div className='container-fluid position-relative px-0'>
          <WorkFlowHeader
            headertext={''}
            ruleUuid={ruleUuid}
            isMobile={isMobile}
            isTab={isTab}
            setMenuOpen={setMenuOpen}
            delRule={delRule}
            setTitleText={setTitleText}
            setRuleId={setRuleUuid}
            setShowConfirm={setShowConfirm}
          />
          <div style={{ height: '22px' }}></div>
          <Form style={{ padding: '20px' }}>
            <div className='row'>
              <div className='col-6'>
                <div
                  className={
                    operationError
                      ? Class_RECT_Error
                      : operationFocus
                        ? Class_RECT_Highlight
                        : Class_RECT
                  }
                >
                  <div>
                    <label
                      className='lbl-input lbl-padding'
                      style={{ marginLeft: '16px' }}
                    >
                      OPERATION CODE
                    </label>

                    <div
                      style={{
                        display: 'flex',
                        color: '#006EED',
                        justifyContent: SB,
                        cursor: 'pointer',
                      }}
                    >
                      {!ruleUuid && (
                        <OperationsFilter
                          labelText={
                            <div
                              className='d-flex'
                              style={{
                                justifyContent: SB,
                                paddingLeft: '15px',
                                width: '300px',
                              }}
                            >
                              <div>
                                {ruleUuid &&
                                rulesList &&
                                rulesList.length > 0 &&
                                rulesList[0].operation_code
                                  ? rulesList[0].operation_code
                                  : operationCode
                                    ? operationCode.operation.name
                                    : 'Select an Operation'}
                              </div>
                              <div>
                                <FaAngleDown />
                              </div>
                            </div>
                          }
                          operationList={operationList}
                          setOperationList={setOperationList}
                          operationCode={operationCode}
                          setOperationCode={setOperationCode}
                          setOperationFocus={setOperationFocus}
                          setOperationError={setOperationError}
                        />
                      )}
                      <div style={{ paddingLeft: '15px' }}>
                        {ruleUuid &&
                          rulesList &&
                          rulesList.length > 0 &&
                          rulesList[0].operation_code}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div
                  className={
                    itemError
                      ? Class_RECT_Error
                      : itemFocus
                        ? Class_RECT_Highlight
                        : Class_RECT
                  }
                  style={{ width: '320px', marginBottom: '20px' }}
                >
                  <div>
                    <label
                      className='lbl-input lbl-padding'
                      style={{ marginLeft: '16px' }}
                    >
                      SELECT ITEM
                    </label>

                    <div
                      style={{
                        display: 'flex',
                        color: '#006EED',

                        cursor: 'pointer',
                      }}
                    >
                      {!ruleUuid && (
                        <div>
                          <ItemMenu
                            actionLabel={
                              <div
                                className='d-flex'
                                style={{
                                  display: SB,
                                  paddingLeft: '10px',
                                  width: '300px',
                                }}
                              >
                                <Form.Control
                                  id={'item'}
                                  style={{ color: '#006EED' }}
                                  type='text'
                                  autoComplete='off'
                                  name={'item'}
                                  onFocus={() => {
                                    if (!itemCode || itemCode === null) {
                                      setItemError(true);
                                      setItemFocus(true);
                                    } else setItemError(false);
                                  }}
                                  value={
                                    itemCode ||
                                    (ruleUuid &&
                                    rulesList &&
                                    rulesList.length > 0 &&
                                    rulesList[0].item_code
                                      ? rulesList[0].item_code
                                      : 'Select the Item')
                                  }
                                />

                                <FaAngleDown
                                  style={{ alignSelf: 'right' }}
                                  onClick={() => setItemError(false)}
                                />
                              </div>
                            }
                            actions={itemList}
                          />
                        </div>
                      )}
                      <div style={{ paddingLeft: '15px' }}>
                        {ruleUuid &&
                          rulesList &&
                          rulesList.length > 0 &&
                          rulesList[0].item_code}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <BottomDrawerWorkstations
            isMobile={isMobile}
            isTab={isTab}
            itemOpen={workstationOpen}
            setItemOpen={setWorkstationOpen}
          />
          <div
            style={{
              maxWidth: '320px',
              marginLeft: '20px',
              marginTop: '30px',
            }}
          >
            <div className='row'>
              <div className='col-9'>Add Rules</div>
              <div
                className='col-3'
                style={{ color: '#006EED', cursor: 'pointer' }}
              >
                <div onClick={() => addRule()}>{'Add+'}</div>
              </div>
            </div>
            <div style={{ height: '20px' }}></div>
            {rulesValues &&
              rulesValues.length > 0 &&
              rulesValues.map((element, index) => {
                return (
                  element.deleted === false && (
                    <div
                      className='row'
                      key={element.uuid || index}
                      style={{ width: '800px' }}
                    >
                      <div className='col-11'>
                        <AddRow
                          index={index}
                          rowValue={element}
                          onUpdateElement={(el, index) => {
                            setRuleValues(v => {
                              if (index >= v.length) {
                                return v;
                              }
                              const _v = cloneDeep(v);
                              _v[`${index}`] = el;
                              return _v;
                            });
                          }}
                          operationFetchList={operationFetchList}
                          operationCode={operationCode}
                        />
                      </div>
                      <div className='col-1' style={{ alignSelf: 'center' }}>
                        <DeleteIcon
                          onClick={() => {
                            setRuleValues(list => {
                              const newList = cloneDeep(list);
                              if (element.deleted === false)
                                newList.find(
                                  l => l.uuid === element.uuid,
                                ).deleted = true;
                              else newList.splice(index, 1);
                              return newList;
                            });
                          }}
                        />
                      </div>
                    </div>
                  )
                );
              })}
          </div>

          <div className='d-flex'>
            <div className='floating-div'>
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{ marginRight: '12px', background: 'white' }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary btn-save-changes'
                onClick={() => {
                  saveChanges();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewWorkflow;
