import React, { useCallback, Fragment, useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { FaAngleDown } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { FaArrowDown } from 'react-icons/fa';
import { RowAction } from '../cutsmart/common';
import { getItemCode } from '../../services/uploadformat';
import Select from '../../components/packing/common/select';
import BottomDrawer from '../../components/template/bottomdrawer';
import CustomHookModal from './customhook-modal';
import { useHistory } from 'react-router-dom';
import SearchIcon from '../../assets/icons/searchIcon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import BottomDrawerItems from './bottom-drawer-items';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
const TXT_COL = 'rgba(50, 50, 50, 0.4)';

const ItemsFilter = ({
  setSelectedItem,
  setSelectedItemCode,
  selectedItemCode,
  isMobile,
  isTab,
  itemOpen,
  setItemOpen,
  itemData,
}) => {
  const itemFilter = term => {
    return new Promise((resolve, reject) => {
      const itemBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (term) itemBody.req_payload.term = term.toUpperCase();
      getItemCode(itemBody).then(val => {
        if (val.status === 200) {
          resolve(val.payload);
        } else {
          reject([]);
        }
      }, reject);
    });
  };
  const getOptions = useCallback(itemFilter, []);
  const history = useHistory();
  return (
    <Fragment>
      <div className='d-flex' style={{ width: '350px', margin: '-10px' }}>
        <div style={{ paddingLeft: '0px' }}>
          <div
            style={{ height: '64px', width: '4px', background: '#008A50' }}
          ></div>
        </div>

        <div style={{ padding: '0px' }}>
          <div style={{ height: '8px' }}></div>

          <div
            className='d-flex'
            style={{ cursor: 'pointer', paddingTop: '0px' }}
          >
            <div
              style={{
                font: 'Roboto',
                fontWeight: '400px',
                fontSize: '24px',
                color: '#006EED',
                paddingLeft: '8px',
              }}
            >
              {selectedItemCode ? selectedItemCode : 'Select Item'}
            </div>

            <div
              style={{
                color: '#323232',
                fontSize: '16px',
                marginLeft: '10px',
              }}
            >
              {!(isMobile || isTab) && (
                <Select
                  //label={`Select an Order`}
                  getOptions={getOptions}
                  labelField='code'
                  valueField='uuid'
                  onSelect={i => {
                    setSelectedItemCode(i.code);
                    setSelectedItem(i);
                    history.push(
                      `/app/uploadformat/additemfeatures?item=${i.code}`,
                    );
                  }}
                  //selected={pipelineMode ? null : selection}
                  //className='page-size-selector dropdown-table-filter'
                  search
                />
              )}
              {(isMobile || isTab) && (
                <div onClick={() => setItemOpen(true)}>
                  <FontAwesomeIcon icon={faChevronDown} className='ms-2' />
                </div>
              )}
              <BottomDrawerItems
                isMobile={isMobile}
                isTab={isTab}
                itemOpen={itemOpen}
                setItemOpen={() => setItemOpen}
                itemData={itemData()}
              />
            </div>
          </div>

          <div
            style={{
              font: 'Roboto',
              fontWeight: 'bold',
              fontSize: '14px',
              color: TXT_COL,
              paddingLeft: '10px',
              paddingTop: '8px',
            }}
          >
            ITEMS
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const UploadInput = ({
  fileExtFocus,
  setFileExtFocus,
  delimiterFocus,
  setDelimiterFocus,
  tableStartFocus,
  setTableStartFocus,
  mapTypeFocus,
  setMapTypeFocus,
  customFocus,
  setCustomFocus,
  fileExtError,
  setFileExtError,
  delimiterError,
  setDelimiterError,
  tableStartError,
  setTableStartError,
  mapTypeError,
  setMapTypeError,
  customError,
  setCustomError,
  form,
  onChange,
  errors,
  isMobile,
  isTab,
  selMapping,
  setSelMapping,
  selectedItemCode,
  setSelectedItem,
  setSelectedItemCode,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const Class_RECT = 'rectangle-input';
  const Class_RECT_Highlight = 'rectangle-input highlight';
  const Class_RECT_Error = 'rectangle-input error';
  const [showHookModal, setShowHookModal] = useState(false);
  const [hookValue, setHookValue] = useState('');
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const [itemsDrawer, setItemsDrawer] = useState([]);
  const [itemOpen, setItemOpen] = useState(false);
  const history = useHistory();
  const itemSelected = useCallback(
    async item => {
      setSelectedItemCode(item.code);
      setSelectedItem(item);
      history.push(`/app/uploadformat/additemfeatures?item=${item.code}`);
    },
    [history, setSelectedItem, setSelectedItemCode],
  );
  const fetchItems = useCallback(
    async tid => {
      const itemBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText) itemBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_ITEM'));
        const getItems = await getItemCode(itemBody);

        if (getItems && getItems.status !== 200) {
          return;
        }

        setItemsDrawer(getItems.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_ITEM'));
      }
    },
    [dispatch, searchText],
  );
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);
  const itemData = () => {
    return (
      <div>
        <div style={{ fontSize: '20px', textAlign: 'center' }}>SELECT ITEM</div>
        <div style={{ height: '30px' }}></div>
        <div>
          <img src={SearchIcon} alt='' style={{ marginRight: '5px' }}></img>
          <input
            type='text'
            id='search_field'
            className='searchModal'
            style={{
              border: '0px !important',
              color: TXT_COL,
              fontStyle: 'italic',
            }}
            value={searchText}
            onChange={e => {
              setSearchText(e.target.value);
            }}
            placeholder='Type here to search Item'
          />
        </div>
        {itemsDrawer &&
          itemsDrawer.length > 0 &&
          itemsDrawer.map(row => (
            <div key={row.uuid}>
              <div
                key={row.uuid}
                style={{
                  fontSize: '14px',
                  height: '20px',
                  paddingTop: '12px',
                  cursor: 'pointer',
                  color: '#006EED',
                }}
                onClick={() => {
                  itemSelected(row);
                  setItemOpen(false);
                }}
              >
                {row.name}
              </div>
              <div
                className='operation-checklist-line'
                style={{
                  borderColor: '#C4C4C4',
                }}
              ></div>
            </div>
          ))}
      </div>
    );
  };
  const closeCallback = setShowHookModal => {
    setShowHookModal(false);
  };
  const downloadFile = () => {
    var FileSaver = require('file-saver');
    var blob = new Blob([form.customhook], {
      type: 'text/plain;charset=utf-8',
    });
    FileSaver.saveAs(blob, 'customhook.js');
  };
  return (
    <div
      className='bg-white'
      style={{
        alignContent: 'left',
        marginLeft: isMobile ? '40px' : '50px',
      }}
    >
      {isMobile && showHookModal ? (
        <BottomDrawer
          entity={''}
          drawerTitle=''
          handleClose={() => closeCallback(setShowHookModal)}
          popUp={showHookModal && isMobile}
          drawerBody={
            <CustomHookModal
              showModal={showHookModal}
              closeCallback={() => closeCallback(setShowHookModal)}
              isMobile={isMobile}
              isTab={isTab}
              hookValue={hookValue}
              setHookValue={setHookValue}
              form={form}
            />
          }
        />
      ) : (
        showHookModal && (
          <CustomHookModal
            showModal={showHookModal}
            closeCallback={() => closeCallback(setShowHookModal)}
            isMobile={isMobile}
            isTab={isTab}
            hookValue={hookValue}
            setHookValue={setHookValue}
            form={form}
          />
        )
      )}
      <ItemsFilter
        setSelectedItem={setSelectedItem}
        setSelectedItemCode={setSelectedItemCode}
        selectedItemCode={selectedItemCode}
        isMobile={isMobile}
        isTab={isTab}
        itemOpen={itemOpen}
        setItemOpen={setItemOpen}
        itemData={itemData}
      />
      <div style={{ height: '27px' }}></div>
      <Form style={{ padding: '0px' }}>
        <div className='row'>
          <div
            className={
              fileExtError
                ? Class_RECT_Error
                : fileExtFocus
                  ? Class_RECT_Highlight
                  : Class_RECT
            }
            style={{
              marginBottom: '20px',
              marginRight: isMobile ? '0px' : '28px',
            }}
          >
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              FILE EXTENSION
            </label>

            <Form.Control
              id='fileext'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='fileext'
              placeholder='Enter file extension'
              value={form.fileext}
              isInvalid={!!errors.name}
              onFocus={() => {
                setFileExtFocus(fileExtFocus => true);
                setFileExtError(fileExtError => false);
                errors.fileExt = '';
              }}
              onBlur={() => {
                setFileExtFocus(fileExtFocus => false);
              }}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
            {errors.name && (
              <Form.Control.Feedback type='invalid'>
                <div style={{ height: '8px' }}></div>
                {errors.fileext}
              </Form.Control.Feedback>
            )}
          </div>

          <div
            className={
              delimiterError
                ? 'row ' + Class_RECT_Error
                : delimiterFocus
                  ? 'row ' + Class_RECT_Highlight
                  : 'row ' + Class_RECT
            }
            style={{ margin: '0px', padding: '0px', marginBottom: '20px' }}
          >
            <div className='col-10'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                DELIMITER
              </label>

              <Form.Control
                id='delimiter'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='delimiter'
                placeholder='Enter a Value'
                value={form.delimiter}
                isInvalid={!!errors.name}
                onFocus={() => {
                  setDelimiterFocus(delimiterFocus => true);
                  setDelimiterError(delimiterError => false);
                  errors.fileExt = '';
                }}
                onBlur={() => {
                  setDelimiterFocus(delimiterFocus => false);
                }}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
            </div>
            <div className='col-2' style={{ paddingTop: '20px' }}></div>
            {errors.name && (
              <Form.Control.Feedback type='invalid'>
                <div style={{ height: '8px' }}></div>
                {errors.delimiter}
              </Form.Control.Feedback>
            )}
          </div>
        </div>

        <div className='row'>
          <div
            className={
              tableStartError
                ? Class_RECT_Error
                : tableStartFocus
                  ? Class_RECT_Highlight
                  : Class_RECT
            }
            style={{
              marginBottom: '20px',
              marginRight: isMobile ? '0px' : '28px',
            }}
          >
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              TABLE START ROW
            </label>

            <Form.Control
              id='tablestart'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='tablestart'
              placeholder='Enter numeric values only'
              value={form.tablestart}
              isInvalid={!!errors.tablestart}
              onFocus={() => {
                setTableStartFocus(tableStartFocus => true);
                setTableStartError(tableStartError => false);
                errors.tableStart = '';
              }}
              onBlur={() => {
                setTableStartFocus(tableStartFocus => false);
              }}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
            {errors.tablestart && (
              <Form.Control.Feedback type='invalid'>
                <div style={{ height: '8px' }}></div>
                {errors.tablestart}
              </Form.Control.Feedback>
            )}
          </div>

          <div
            className={
              mapTypeError
                ? 'row ' + Class_RECT_Error
                : mapTypeFocus
                  ? 'row ' + Class_RECT_Highlight
                  : 'row ' + Class_RECT
            }
            style={{ margin: '0px', padding: '0px', marginBottom: '20px' }}
          >
            <div className='col-10'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                MAPPING TYPE
              </label>

              <Form.Control
                id='maptype'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='maptype'
                placeholder='Select a Value'
                value={selMapping ? selMapping : form.maptype}
                isInvalid={!!errors.maptype}
                onFocus={() => {
                  setMapTypeFocus(mapTypeFocus => true);
                  setMapTypeError(maptypeError => false);
                  errors.maptype = '';
                }}
                onBlur={() => {
                  setMapTypeFocus(mapTypeFocus => false);
                }}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
            </div>
            <div className='col-2' style={{ paddingTop: '20px' }}>
              <RowAction
                actionLabel={<FaAngleDown />}
                actions={[
                  {
                    key: '0',
                    label: 'HEADER',
                    onClick: () => {
                      setSelMapping('HEADER');
                    },
                  },
                  {
                    key: '1',
                    label: 'COLUMN',
                    onClick: () => {
                      setSelMapping('COLUMN');
                    },
                  },
                ]}
              />
            </div>
            {errors.maptype && (
              <Form.Control.Feedback type='invalid'>
                <div style={{ height: '8px' }}></div>
                {errors.maptype}
              </Form.Control.Feedback>
            )}
          </div>
        </div>

        <div className='row'>
          <div
            className={
              customError
                ? Class_RECT_Error
                : customFocus
                  ? Class_RECT_Highlight
                  : Class_RECT
            }
            style={{
              marginBottom: '20px',
              marginRight: isMobile ? '0px' : '28px',
            }}
            onClick={() => setShowHookModal(true)}
          >
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              CUSTOM HOOK
            </label>

            <Form.Control
              id='customhook'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='customhook'
              placeholder='Enter custom hook here'
              value={form.customhook}
              isInvalid={!!errors.customhook}
              onFocus={() => {
                setCustomFocus(customFocus => true);
                setCustomError(customError => false);
                errors.customhook = '';
              }}
              onBlur={() => {
                setCustomFocus(customFocus => false);
              }}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
            {errors.custom && (
              <Form.Control.Feedback type='invalid'>
                <div style={{ height: '8px' }}></div>
                {errors.custom}
              </Form.Control.Feedback>
            )}
          </div>

          <div
            className='row'
            style={{
              marginBottom: '20px',
              paddingLeft: '30px',
              maxWidth: '320px',
              verticalAlign: 'center',
              color: '#DEDEDE',
            }}
          >
            <div style={{ height: '6px' }}></div>
            <div className='d-flex' style={{ height: 'fit-content' }}>
              <div>Validate</div>
              <div style={{ width: '4px' }}></div>
              <div>
                <FaCheck />
              </div>
              <div style={{ width: '18px' }}></div>
              <div
                onClick={form.customhook ? downloadFile : ''}
                style={{
                  display: 'flex',
                  cursor: form.customhook ? 'pointer' : '',
                  color: form.customhook ? '#006EED' : '',
                }}
              >
                <div>Download</div>
                <div style={{ width: '4px' }}></div>
                <div>
                  <FaArrowDown />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default UploadInput;
