import React, { useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { FaAngleDown } from 'react-icons/fa';
import BottomDrawer from '../../components/template/bottomdrawer';
import CustomHookModal from './editquery-modal';
import GreyClose from '../../assets/icons/grey-close.svg';
import { cloneDeep } from 'lodash';
import { Menu, Dropdown } from 'antd';
import '../../scss/bot/_bot.scss';

const BotInput = ({
  bottype,
  addParameterClick,
  botnameFocus,
  setBotNameFocus,
  batchuploadFocus,
  setBatchuploadFocus,
  queryFocus,
  setQueryFocus,
  botnameError,
  setBotNameError,
  batchuploadError,
  setBatchuploadError,
  queryError,
  setQueryError,
  form,
  setForm,
  onChange,
  errors,
  isMobile,
  isTab,
  addParameter,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const Class_RECT = 'rectangle-input';
  const Class_RECT_Highlight = 'rectangle-input highlight';
  const Class_RECT_Error = 'rectangle-input error';
  const [showHookModal, setShowHookModal] = useState(false);
  const [hookValue, setHookValue] = useState('');
  const closeCallback = setShowHookModal => {
    setShowHookModal(false);
  };
  const setSelType = (valSel, index) => {
    setForm(val => {
      const newVal = cloneDeep(val);
      newVal[`type${index}`] = valSel;
      return newVal;
    });
  };
  const setSelParamTypeVal = (valSel, index) => {
    setForm(val => {
      const newVal = cloneDeep(val);
      newVal[`param_type${index}`] = valSel;
      return newVal;
    });
  };
  const AddRow = useCallback(
    ({ index, typeVal, nameVal, valueVal, templateVal, paramtypeVal }) => {
      return (
        <div
          key={index}
          className='row'
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <div className='col-2'>
            <Dropdown
              overlay={() => {
                return <TypeMenu indexVal={index} />;
              }}
              trigger='click'
            >
              <div className='col-3 row' style={{ cursor: 'pointer' }}>
                <div className='col-8'>
                  <Form.Control
                    id={'type' + index}
                    className='txt-input-param primary'
                    type='text'
                    autoComplete='off'
                    name={'type' + index}
                    placeholder='Type'
                    value={`${typeVal}`}
                    onChange={onChange}
                  />
                </div>
                <div className='col-4'>
                  <FaAngleDown style={{ paddingBottom: '5px' }} />
                </div>
              </div>
            </Dropdown>
          </div>
          <div className='col-2'>
            {' '}
            <Form.Control
              id={'name' + index}
              className='txt-input-param primary'
              type='text'
              autoComplete='off'
              name={'name' + index}
              placeholder='Name'
              value={`${nameVal}`}
              onChange={onChange}
            />
          </div>
          <div className='col-2'>
            <Form.Control
              id={'value' + index}
              className='txt-input-param primary'
              type='text'
              autoComplete='off'
              name={'value' + index}
              placeholder='Value'
              value={`${valueVal}`}
              onChange={onChange}
            />
          </div>
          <div className='col-3'>
            {' '}
            <Form.Control
              id={'template' + index}
              className='txt-input-param primary'
              type='text'
              autoComplete='off'
              name={'template' + index}
              placeholder='Template'
              value={`${templateVal}`}
              onChange={onChange}
            />
          </div>
          <Dropdown
            overlay={() => {
              return <ParamTypeMenu indexVal={index} />;
            }}
            trigger='click'
          >
            <div className='col-3 row' style={{ cursor: 'pointer' }}>
              <div className='col-8'>
                <Form.Control
                  id={'param_type' + index}
                  className='txt-input-param primary'
                  type='text'
                  autoComplete='off'
                  name={'param_type' + index}
                  placeholder='Click'
                  value={`${paramtypeVal}`}
                  onChange={onChange}
                />
              </div>
              <div className='col-4'>
                <FaAngleDown style={{ paddingBottom: '5px' }} />
              </div>
            </div>
          </Dropdown>
        </div>
      );
    },
    [onChange],
  );
  const TypeMenu = ({ indexVal }) => {
    return (
      <Menu
        className='menuItems'
        style={{
          padding: 0,
          background: '#ffffff',
          border: '1px solid #c0c0c0',
          boxSizing: 'borderBox',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: '8px',
        }}
      >
        <Menu.Item key='0' onClick={() => setSelType('STRING', indexVal)}>
          STRING
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='1' onClick={() => setSelType('NUMBER', indexVal)}>
          NUMBER
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='2' onClick={() => setSelType('BOOL', indexVal)}>
          BOOL
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='3' onClick={() => setSelType('LONG', indexVal)}>
          LONG
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='4' onClick={() => setSelType('INT', indexVal)}>
          INT
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key='5' onClick={() => setSelType('DOUBLE', indexVal)}>
          DOUBLE
        </Menu.Item>
      </Menu>
    );
  };
  const ParamTypeMenu = ({ indexVal }) => {
    return (
      <Menu
        className='menuItems'
        style={{
          padding: 0,
          background: '#ffffff',
          border: '1px solid #c0c0c0',
          boxSizing: 'borderBox',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
          borderRadius: '8px',
        }}
      >
        <Menu.Item
          key='0'
          onClick={() => setSelParamTypeVal('QUERY', indexVal)}
        >
          QUERY
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key='1'
          onClick={() => setSelParamTypeVal('GENERIC', indexVal)}
        >
          GENERIC
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div
      className='bg-white'
      style={{
        alignContent: 'left',
        marginLeft: '40px',
      }}
    >
      {isMobile && showHookModal ? (
        <BottomDrawer
          entity={''}
          drawerTitle=''
          handleClose={() => closeCallback(setShowHookModal)}
          popUp={showHookModal && isMobile}
          drawerBody={
            <CustomHookModal
              showModal={showHookModal}
              closeCallback={() => closeCallback(setShowHookModal)}
              isMobile={isMobile}
              isTab={isTab}
              hookValue={hookValue}
              setHookValue={setHookValue}
              form={form}
              bottype={bottype}
            />
          }
        />
      ) : (
        showHookModal && (
          <CustomHookModal
            showModal={showHookModal}
            closeCallback={() => closeCallback(setShowHookModal)}
            isMobile={isMobile}
            isTab={isTab}
            hookValue={hookValue}
            setHookValue={setHookValue}
            form={form}
            bottype={bottype}
          />
        )
      )}

      <div style={{ height: '27px' }}></div>
      <Form style={{ padding: '0px' }}>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='row m-0 p-0'>
            <div className='col-md-10 col-sm-10 col-10 m-0 p-0'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                BOT TYPE
              </label>

              <Form.Control
                id='bottype'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='bottype'
                placeholder='Enter Value Here'
                value={bottype}
                disabled
                isInvalid={!!errors.bottype}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
            </div>
          </div>
        </div>
        <div
          className={
            botnameError
              ? Class_RECT_Error
              : botnameFocus
                ? Class_RECT_Highlight
                : Class_RECT
          }
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='row m-0 p-0'>
            <div className='col-md-10 col-sm-10 col-10 m-0 p-0'>
              <label
                className='lbl-input lbl-padding'
                style={{ marginLeft: '16px' }}
              >
                BOT NAME
              </label>

              <Form.Control
                id='botname'
                className='txt-input primary'
                type='text'
                autoComplete='off'
                name='botname'
                placeholder='Enter Value Here'
                value={form.botname}
                isInvalid={!!errors.botname}
                onFocus={() => {
                  setBotNameFocus(botnameFocus => true);
                  setBotNameError(botnameError => false);
                  errors.botname = '';
                }}
                onBlur={() => {
                  setBotNameFocus(botnameFocus => false);
                }}
                onChange={onChange}
                style={{ marginLeft: '16px' }}
              />
              {errors.botname && (
                <Form.Control.Feedback type='invalid'>
                  <div style={{ height: '8px' }}></div>
                  {errors.botname}
                </Form.Control.Feedback>
              )}
            </div>
            <div
              className='col-md-2 col-sm-2 col-2 password-image'
              style={{ paddingTop: '15px', textAlignLast: 'right' }}
            >
              <img src={GreyClose} alt=''></img>
            </div>
          </div>
        </div>
        <div
          className={
            batchuploadError
              ? 'row ' + Class_RECT_Error
              : batchuploadFocus
                ? 'row ' + Class_RECT_Highlight
                : 'row ' + Class_RECT
          }
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='col-10'>Batch Upload</div>
          <Form.Check
            className='col-2'
            type='switch'
            id='batchupload'
            name='batchupload'
            onFocus={() => {
              setBatchuploadFocus(true);
              setBatchuploadError(false);
            }}
            onBlur={() => {
              setBatchuploadFocus(false);
            }}
            onChange={() => {
              setForm(val => {
                const newVal = cloneDeep(val);
                newVal.batchupload = !newVal.batchupload;
                return newVal;
              });
            }}
            value={true}
            checked={form.batchupload === true}
          />
        </div>

        <div
          className={
            queryError
              ? Class_RECT_Error
              : queryFocus
                ? Class_RECT_Highlight
                : Class_RECT
          }
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
          onClick={() => setShowHookModal(true)}
        >
          <label
            className='lbl-input lbl-padding'
            style={{ marginLeft: '16px' }}
          >
            QUERY
          </label>

          <Form.Control
            id='query'
            className='txt-input primary'
            type='text'
            autoComplete='off'
            name='query'
            placeholder='Enter Value Here'
            value={form.query}
            isInvalid={!!errors.query}
            onFocus={() => {
              setQueryFocus(queryFocus => true);
              setQueryError(queryError => false);
              errors.query = '';
            }}
            onBlur={() => {
              setQueryFocus(queryFocus => false);
            }}
            onChange={onChange}
            style={{ marginLeft: '16px' }}
          />
          {errors.query && (
            <Form.Control.Feedback type='invalid'>
              <div style={{ height: '8px' }}></div>
              {errors.query}
            </Form.Control.Feedback>
          )}
        </div>
        <div style={{ width: '320px' }}>
          <div
            className='row'
            style={{ paddingBottom: '20px', paddingTop: '20px' }}
          >
            <div className='col-10'>Parameters</div>
            <div
              className='col-2'
              onClick={addParameter}
              style={{ color: '#006EED', cursor: 'pointer' }}
            >
              Add+
            </div>
          </div>
          <div
            className='row'
            style={{
              borderBottom: 'solid',
              borderColor: 'rgba(50, 50, 50, 0.4)',
            }}
          >
            <div className='col-2'>Type</div>
            <div className='col-2'>Name</div>
            <div className='col-2'>Value</div>
            <div className='col-3'>Template</div>
            <div className='col-3'>Param Type</div>
          </div>

          {addParameterClick &&
            addParameterClick.length > 0 &&
            addParameterClick.map((element, index) => {
              return (
                <AddRow
                  key={index || element}
                  index={index}
                  typeVal={
                    form[`type${index}`] === ''
                      ? ''
                      : form[`type${index}`] || element.type || ''
                  }
                  nameVal={
                    form[`name${index}`] === ''
                      ? ''
                      : form[`name${index}`] || element.name || ''
                  }
                  valueVal={
                    form[`value${index}`] === ''
                      ? ''
                      : form[`value${index}`] || element.value || ''
                  }
                  templateVal={
                    form[`template${index}`] === ''
                      ? ''
                      : form[`template${index}`] || element.template || ''
                  }
                  paramtypeVal={
                    form[`param_type${index}`] === ''
                      ? ''
                      : form[`param_type${index}`] || element.param_type || ''
                  }
                />
              );
            })}
        </div>

        <div style={{ paddingTop: '35px', paddingBottom: '15px' }}>
          Configuration
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Enable Trace
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enabletrace'
              name='enabletrace'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enabletrace = !newVal.enabletrace;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enabletrace === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            margin: '0px',
            paddingTop: '15px',
            marginBottom: '20px',
          }}
        >
          <div className='row'>
            <div className='col-10' style={{ paddingLeft: '30px' }}>
              Enable Curl
            </div>
            <Form.Check
              className='col-2'
              type='switch'
              id='enablecurl'
              name='enablecurl'
              onChange={() => {
                setForm(val => {
                  const newVal = cloneDeep(val);
                  newVal.enablecurl = !newVal.enablecurl;
                  return newVal;
                });
              }}
              value={true}
              checked={form.enablecurl === true}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Database
            </label>

            <Form.Control
              id='database'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='database'
              placeholder='Enter Value Here'
              value={form.database}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              User Name
            </label>

            <Form.Control
              id='username'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='username'
              placeholder='Enter Value Here'
              value={form.username}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Password
            </label>

            <Form.Control
              id='password'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='password'
              placeholder='Enter Value Here'
              value={form.password}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Port
            </label>

            <Form.Control
              id='port'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='port'
              placeholder='Enter Value Here'
              value={form.port}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Host
            </label>

            <Form.Control
              id='host'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='host'
              placeholder='Enter Value Here'
              value={form.host}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Image Path
            </label>

            <Form.Control
              id='image_path'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='image_path'
              placeholder='Enter Value Here'
              value={form.image_path}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Program Path
            </label>

            <Form.Control
              id='program_path'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='program_path'
              placeholder='Enter Value Here'
              value={form.program_path}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Query Template
            </label>

            <Form.Control
              id='query_template'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='query_template'
              placeholder='Enter Value Here'
              value={form.query_template}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Image Template
            </label>

            <Form.Control
              id='image_template'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='image_template'
              placeholder='Enter Value Here'
              value={form.image_template}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
        <div
          className={Class_RECT}
          style={{
            marginBottom: '20px',
            marginRight: isMobile ? '0px' : '28px',
          }}
        >
          <div className='m-0 p-0'>
            <label
              className='lbl-input lbl-padding'
              style={{ marginLeft: '16px' }}
            >
              Program File Extension
            </label>

            <Form.Control
              id='program_file_ext'
              className='txt-input primary'
              type='text'
              autoComplete='off'
              name='program_file_ext'
              placeholder='Enter Value Here'
              value={form.program_file_ext}
              onChange={onChange}
              style={{ marginLeft: '16px' }}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default BotInput;
