import { createReducer, createAction } from '@reduxjs/toolkit';
import Update from 'immutability-helper';
import { cloneDeep } from 'lodash';
const SHIPMENT_COLLABORATOR_NAME = 'Shared Shipment';
const SHIPMENT_COLLABORATOR_KEY = 'SHIPMENT_COLLABORATOR';
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}
export const TYPES = {
  CURRENT_STATION: 'CURRENT_STATION',
  BRANCH_STATIONS: 'BRANCH_STATIONS',
  BRANCH_CURRENT_STATION: 'BRANCH_CURRENT_STATION',
  STATION_CTX: 'STATION_CTX',
  UPDATE_CACHE: 'UPDATE_CACHE',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  PENDING_SYNC: 'PENDING_SYNC',
  GO_BACK: 'GO_BACK',
  REJECT_MODE: 'REJECT_MODE',
  SELECTED_DPOD: 'SELECTED_DPOD',
  DPOD_EXTERNAL_STATE: 'DPOD_EXTERNAL_STATE',
  COLLABORATOR_SHIPMENT_ORDERS: 'COLLABORATOR_SHIPMENT_ORDERS',
  UPDATE_PENDING_SYNC_ORDERS: 'UPDATE_PENDING_SYNC_ORDERS',
  REMOVE_PENDING_SYNC_ORDERS: 'REMOVE_PENDING_SYNC_ORDERS',
  CURRENT_SCAN_ITEM: 'CURRENT_SCAN_ITEM',
  REJECTION_REASONS: 'REJECTION_REASONS',
  REJECTION_REASON_MODAL: 'REJECTION_REASON_MODAL',
  LAST_SCAN_ITEM: 'LAST_SCAN_ITEM',
  POPULATING_DPOD_DATA: 'POPULATING_DPOD_DATA',
};
const getActionName = (type, verb) =>
  actionTypeName('DIGITALPOD_EXTERNAL_STATION_STATE', type, verb);
export const setDpodExternalStationCtxType = createAction(
  getActionName(TYPES.STATION_CTX, 'TYPESET'),
);
export const setDpodExternalStationCtxValue = createAction(
  getActionName(TYPES.STATION_CTX, 'VALSET'),
);

export const resetDPodExternalStationState = createAction(
  getActionName('RESET', 'RESET'),
);
export const setDPodExternalCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'SET'),
);

export const resetDPodExternalCurrentStation = createAction(
  getActionName(TYPES.CURRENT_STATION, 'RESET'),
);
export const setRejectMode = createAction(
  getActionName(TYPES.REJECT_MODE, 'SET'),
);
export const setDPodExternalBranchStations = createAction(
  getActionName(TYPES.BRANCH_STATIONS, 'SET'),
);

export const setDPodExternalBranchCurrentStation = createAction(
  getActionName(TYPES.BRANCH_CURRENT_STATION, 'SET'),
);

export const setDPodExternalStationCtxType = createAction(
  getActionName(TYPES.STATION_CTX, 'TYPESET'),
);
export const setDPodExternalStationCtxValue = createAction(
  getActionName(TYPES.STATION_CTX, 'VALSET'),
);

export const updateDPodExternalStationCache = createAction(
  getActionName(TYPES.UPDATE_CACHE, 'SET'),
);

export const updateDPodExternalStationLocation = createAction(
  getActionName(TYPES.UPDATE_LOCATION, 'MERGE'),
);

export const setDPodExternalPendingSync = createAction(
  getActionName(TYPES.PENDING_SYNC, 'SET'),
);
export const setDPodExternalRefreshCache = createAction(
  getActionName(TYPES.PENDING_SYNC, 'REFRESH'),
);
export const setGoBack = createAction(getActionName(TYPES.GO_BACK, 'SET'));
export const setSelecteDPod = createAction(
  getActionName(TYPES.SELECTED_DPOD, 'SET'),
);
export const setDPodExternalState = createAction(
  getActionName(TYPES.DPOD_EXTERNAL_STATE, 'SET'),
);
export const setCollabShipments = createAction(
  getActionName(TYPES.COLLABORATOR_SHIPMENT_ORDERS, 'SET'),
);

export const updatePendSyncStateItems = createAction(
  getActionName(TYPES.UPDATE_PENDING_SYNC_ORDERS, 'UPDATE'),
);
export const removePendSyncStateItems = createAction(
  getActionName(TYPES.REMOVE_PENDING_SYNC_ORDERS, 'UPDATE'),
);
export const setCurrentScanItem = createAction(
  getActionName(TYPES.CURRENT_SCAN_ITEM, 'UPDATE'),
);
export const setRejectionReasons = createAction(
  getActionName(TYPES.REJECTION_REASONS, 'UPDATE'),
);
export const setRejectReasonModal = createAction(
  getActionName(TYPES.REJECTION_REASON_MODAL, 'SET'),
);
export const setLastScanItem = createAction(
  getActionName(TYPES.LAST_SCAN_ITEM, 'SET'),
);
export const setPopulatingDPodData = createAction(
  getActionName(TYPES.POPULATING_DPOD_DATA, 'SET'),
);
export const DEFAULT = {
  current_station: {
    uuid: 'digitalpod',
    org_id: '',
    branch_id: '',
    instance_name: 'digitalpod',
    station_group: 'digitalpod',
    station_code: 'digitalpod',
    station_name: 'Digital POD',
    operations: ['DIGITALPOD'],
    parent_operations: [],
    allow_scan_uploads: false,
    allow_box_scan: true,
    allow_offline_scan: true,
    auto_operation: false,
    supported_item_type: [],
    enable_item_print: false,
  },
  defaultFeatures: [
    'MATERIAL',
    'CUT_LENGTH',
    'CUT_WIDTH',
    'INVENTORY_TYPE',
    'REJECT_REASON_TEMPLATE',
    'REJECTION_REASONS',
  ],
  ctx: {
    type: { label: 'Shipment', value: 'SHIPMENT' },
    value: {},
  },
  branch_stations: {},
  station_location: {},
  station_cache: {
    order_map: {},
    result: [],
  },
  pending_sync: false,
  refresh_cache: false,
  go_back: '',
  reject_mode: false,
  selecteDPod: {},
  dPodExternalState: [],
  collabShipments: [],
  pending_sync_state: [],
  currentScanItem: {},
  rejectionReasons: [],
  rejection_reasons_modal: false,
  lastScanItem: {},
  populatingDPodData: false,
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(resetDPodExternalStationState, state => {
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: {} },
      ctx: {
        $set: {
          type: {
            label: SHIPMENT_COLLABORATOR_NAME,
            value: SHIPMENT_COLLABORATOR_KEY,
          },
          value: {},
        },
      },
      station_location: { $set: {} },
      station_cache: {
        $set: {
          order_map: {},
          result: [],
        },
      },
    });
  });
  builder.addCase(resetDPodExternalCurrentStation, state => {
    const branch_stations = cloneDeep(state.branch_stations);
    const current_station = state.current_station;
    if (current_station && branch_stations[`${current_station.branch_id}`]) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    return Update(state, {
      current_station: { $set: null },
      branch_stations: { $set: branch_stations },
    });
  });
  builder.addCase(setDPodExternalCurrentStation, (state, { payload }) => {
    const branch_stations = cloneDeep(state.branch_stations);
    let ctx = cloneDeep(state.ctx) || {};
    const current_station = state.current_station;
    if (
      !payload &&
      current_station &&
      branch_stations[`${current_station.branch_id}`]
    ) {
      delete branch_stations[`${current_station.branch_id}`];
    }
    if (
      current_station &&
      ctx &&
      ctx.value &&
      ctx.value.branch_id !== current_station.branch_id
    ) {
      ctx = {
        type: {
          label: SHIPMENT_COLLABORATOR_NAME,
          value: SHIPMENT_COLLABORATOR_KEY,
        },
        value: {},
      };
    }
    if (payload && payload.branch_id) {
      branch_stations[`${payload.branch_id}`] = payload;
    }

    return Update(state, {
      current_station: { $set: payload },
      branch_stations: { $set: branch_stations },
      ctx: { $set: ctx },
    });
  });

  builder.addCase(setDPodExternalBranchCurrentStation, state => {
    const branch_stations = state.branch_stations;
    const user = state.user;
    let current_station = null;
    if (
      user &&
      user.branch &&
      user.branch.branch_id &&
      branch_stations[user.branch.branch_id]
    ) {
      current_station = branch_stations[user.branch.branch_id];
    }
    return Update(state, {
      current_station: { $set: current_station },
    });
  });
  builder.addCase(setDPodExternalStationCtxType, (state, { payload }) => {
    const _payload =
      payload && payload.label && payload.value
        ? payload
        : {
            label: SHIPMENT_COLLABORATOR_NAME,
            value: SHIPMENT_COLLABORATOR_KEY,
          };
    const _ctx = cloneDeep(state.ctx);
    if (
      !_payload ||
      !_payload.value ||
      (_ctx.type && _ctx.type.value !== _payload.value)
    ) {
      _ctx.value = {};
    }
    _ctx.type = _payload;
    return Update(state, {
      ctx: {
        $set: _ctx,
      },
    });
  });
  builder.addCase(setDPodExternalStationCtxValue, (state, { payload }) => {
    const _ctx = cloneDeep(state.ctx);
    _ctx.value = payload;
    return Update(state, {
      ctx: {
        $set: _ctx,
      },
    });
  });

  builder.addCase(updateDPodExternalStationLocation, (state, { payload }) => {
    return Update(state, {
      station_location: { $merge: payload },
    });
  });
  builder.addCase(updateDPodExternalStationCache, (state, { payload }) => {
    const p = payload
      ? payload
      : {
          stat: {},
          order_map: {},
          result: [],
        };
    return Update(state, {
      station_cache: { $set: p },
    });
  });
  builder.addCase(setDPodExternalPendingSync, (state, { payload }) => {
    return Update(state, {
      pending_sync: { $set: payload === true },
    });
  });
  builder.addCase(setDPodExternalRefreshCache, (state, { payload }) => {
    return Update(state, {
      refresh_cache: { $set: payload === true },
    });
  });
  builder.addCase(setGoBack, (state, { payload }) => {
    return Update(state, {
      go_back: { $set: payload },
    });
  });
  builder.addCase(setRejectMode, (state, { payload }) => {
    return Update(state, {
      reject_mode: { $set: payload },
    });
  });
  builder.addCase(setSelecteDPod, (state, { payload }) => {
    return Update(state, {
      selecteDPod: { $set: payload },
    });
  });
  builder.addCase(setDPodExternalState, (state, { payload }) => {
    return Update(state, {
      dPodExternalState: { $set: payload },
    });
  });
  builder.addCase(setCollabShipments, (state, { payload }) => {
    return Update(state, {
      collabShipments: { $set: payload },
    });
  });
  builder.addCase(setCurrentScanItem, (state, { payload }) => {
    return Update(state, {
      currentScanItem: { $set: payload },
    });
  });
  builder.addCase(setRejectionReasons, (state, { payload }) => {
    return Update(state, {
      rejectionReasons: { $set: payload },
    });
  });
  builder.addCase(updatePendSyncStateItems, (state, { payload, item }) => {
    if (!payload || !item) {
      return;
    }
    const existingStateIndex = state.pending_sync_state.findIndex(
      pod => pod.dPod_id === payload.dPod_id,
    );

    if (existingStateIndex !== -1) {
      const { result, ...restPayload } = payload;

      state.pending_sync_state[`${existingStateIndex}`] = {
        ...restPayload,
        result: state.pending_sync_state[`${existingStateIndex}`]?.result || [],
      };

      const existingItemIndex = state.pending_sync_state[
        `${existingStateIndex}`
      ].result.findIndex(resultItem => resultItem.item_id === item.item_id);

      if (existingItemIndex !== -1) {
        state.pending_sync_state[`${existingStateIndex}`].result[
          `${existingItemIndex}`
        ] = item;
      } else {
        state.pending_sync_state[`${existingStateIndex}`].result.push(item);
      }
    } else {
      const { result, ...restPayload } = payload;
      state.pending_sync_state.push({
        ...restPayload,
        result: [item],
      });
    }
  });
  builder.addCase(removePendSyncStateItems, (state, { payload, item }) => {
    if (!payload || !item) {
      return;
    }
    const existingStateIndex = state.pending_sync_state.findIndex(
      pod => pod.dPod_id === payload.dPod_id,
    );

    if (existingStateIndex !== -1) {
      const existingItemIndex = state.pending_sync_state[
        `${existingStateIndex}`
      ].result.findIndex(resultItem => resultItem.item_id === item.item_id);

      if (existingItemIndex !== -1) {
        state.pending_sync_state[`${existingStateIndex}`].result.splice(
          `${existingItemIndex}`,
          1,
        );
      }

      if (
        state.pending_sync_state[`${existingStateIndex}`].result.length === 0
      ) {
        state.pending_sync_state.splice(existingStateIndex, 1);
      }
    }
  });
  builder.addCase(setRejectReasonModal, (state, { payload }) => {
    return Update(state, {
      rejection_reasons_modal: { $set: payload === true },
    });
  });
  builder.addCase(setLastScanItem, (state, { payload }) => {
    return Update(state, {
      lastScanItem: { $set: payload },
    });
  });
  builder.addCase(setPopulatingDPodData, (state, { payload }) => {
    return Update(state, {
      populatingDPodData: { $set: payload },
    });
  });
});

export default reducer;
