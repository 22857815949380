import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FormCutsmartSetting from './formcutsmartsetting';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import FormLabel from 'react-bootstrap/esm/FormLabel';
import { IconInfoCircled, IconInfoCircledWhite } from './iconSetting';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { getOrgConfigs, updateOrgConfigs } from '../../services/org';
import Select from '../../components/uploadformat/select-upload';
import BlackClose from '../../assets/icons/black-close.svg';
import ScreenLoader from '../../components/common/screen-loader';
import {
  createFeatureItem,
  createItem,
  getItemByCode,
} from '../../services/items';
import { toastError } from '../../helpers/packing/packingHelper';
import { ToastContainer } from 'react-toastify';
import { checkPermission } from '../../services/order';
const OPTIMIZATION_TYPES = ['BALANCED', 'DEFAULT', 'MATERIAL_SAVER'];
const ORG_CONFIG_KEYS = [
  'CUTSMART_ITEM_FEATURES_TEMPLATE',
  'CUTSMART_MATERIAL_ITEM_GRAIN_VAL_SKIP',
  'OPTIMIZATION_TYPES',
  ...OPTIMIZATION_TYPES.map(val => 'CUTSMART_OPTIMIZATION_TYPE_' + val),
];
const OPTIMIZATION_TYPE_CONFIG = 'OPTIMIZATION_TYPES';
const CUTSMART_MATERIAL_ITEM_GRAIN_VAL_SKIP =
  'CUTSMART_MATERIAL_ITEM_GRAIN_VAL_SKIP';
const CUTSMART_ITEM_FEATURES_TEMPLATE = 'CUTSMART_ITEM_FEATURES_TEMPLATE';
const CutSmartSetting = () => {
  const [grainValueSkip, setGrainValueSkip] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [material, setMaterial] = useState({});
  const [materialState, setMaterialState] = useState(false);
  const [optimizationTypes, setOptimizationTypes] = useState([]);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [itemsList, setItemsList] = useState([]);
  const [overhang, setOverhang] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const featuresArray = selectedItems.map(item => item.value);
  const [formData, setFormData] = useState({
    template0: '',
    field_name0: '',
    template1: '',
    field_name1: '',
  });
  const [initialFormData, setInitialFormData] = useState({});

  const formHandleInput = useCallback(event => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  }, []);
  const extractPlaceholders = template => {
    const regex = /\{\{(.*?)\}\}/g;
    const matches = [];
    let match;
    while ((match = regex.exec(template)) !== null) {
      matches.push(match[1].trim());
    }
    return matches;
  };
  const cleanedTemplates = [formData.template0, formData.template1].map(
    template => (template ? extractPlaceholders(template) : []),
  );

  const selectedValues = featuresArray;
  const blockingTemplates = cleanedTemplates
    .flat()
    .filter(template => template && !selectedValues.includes(template));
  const handleFieldChange = (index, fieldName, value) => {
    setOptimizationTypes(prevState => {
      return prevState.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            [fieldName]: value,
          };
        }
        return item;
      });
    });
  };

  const handleInputChange = key => e => {
    const { value } = e.target;
    const isValidInput = value === '' || value.match(/^\d*\.?\d*$/);

    if (isValidInput) {
      const numericValue = value === '' ? '' : parseFloat(value);

      setMaterial(prevMaterial => ({
        ...prevMaterial,
        [key]:
          numericValue === '' || numericValue >= 0
            ? value
            : prevMaterial[`${key}`],
      }));
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleFetchData = useCallback(async () => {
    setIsLoading(true);
    let isSaveEnable = true;
    try {
      const response = await getOrgConfigs(ORG_CONFIG_KEYS);
      const arr = [];
      const optimizationTypeConfig = response.payload.filter(
        ee => ee.key === OPTIMIZATION_TYPE_CONFIG,
      )[0] || { value: { val: [] } };
      const grainValueSkipConfig = response.payload.find(
        item => item.key === CUTSMART_MATERIAL_ITEM_GRAIN_VAL_SKIP,
      )?.value.val;
      if (grainValueSkipConfig !== grainValueSkip) {
        setGrainValueSkip(grainValueSkipConfig);
      }

      OPTIMIZATION_TYPES.map(val => {
        return { type: val, config_key: 'CUTSMART_OPTIMIZATION_TYPE_' + val };
      }).forEach(optimizationType => {
        const type = optimizationType.type;
        const filterObj =
          response.payload.find(ee => ee.key === optimizationType.config_key) ||
          {};
        const hasValue = !!filterObj.key;
        const objToPush = {
          optimized: optimizationType.type,
          key: hasValue ? filterObj.key : optimizationType.config_key,
          title: optimizationType.type,
          checked: (optimizationTypeConfig?.value?.val || []).includes(type),
          optimization_level: hasValue
            ? filterObj.value.val.optimization_level
            : 'selectValue',
          book_cut: hasValue ? filterObj.value.val.book_cut || false : false,
          waste_min: hasValue ? filterObj.value.val.waste_min : 0,
          minimize_sheet_rotation: hasValue
            ? filterObj.value.val.minimize_sheet_rotation || false
            : false,
        };
        arr.push(objToPush);
      });
      setOptimizationTypes(arr);
      try {
        const patternImgResponse = response.payload.find(
          item => item.key === CUTSMART_ITEM_FEATURES_TEMPLATE,
        )?.value?.val;
        const newFormData = {};
        patternImgResponse?.fields?.forEach((field, index) => {
          newFormData[`field_name${index}`] = field.field_name;
          newFormData[`template${index}`] = field.template;
        });
        setFormData(newFormData);
        setInitialFormData(newFormData);
        setSelectedItems(
          patternImgResponse.features.map(feature => ({
            key: feature,
            value: feature,
          })),
        );
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      toastError('unable to fetch values reload the page');
      isSaveEnable = false;
    }
    try {
      const materialResponse = await getItemByCode('MATERIAL');
      if (materialResponse.status !== 200 || !materialResponse.payload) {
        setMaterialState(true);
        return toastError(
          'MATERIAL ITEM  is not created please create MATERIAL ITEM',
        );
      }
      const materialItem = materialResponse?.payload[0];
      // eslint-disable-next-line sonarjs/prefer-object-literal
      let materialObj = {};
      materialObj.default_width =
        materialItem?.definition?.DEFAULT_WIDTH?.value?.val || 0;
      materialObj.default_length =
        materialItem?.definition.DEFAULT_LENGTH?.value?.val;
      materialObj.trim = materialItem?.definition?.TRIM?.value?.val;
      materialObj.kerf = materialItem?.definition?.KERF?.value?.val;
      materialObj.grain = materialItem?.definition?.GRAIN?.value?.val === true;
      materialObj.code = materialItem?.code;
      materialObj.uuid = materialItem?.uuid;
      setMaterial(materialObj);
    } catch (error) {
      toastError('unable to fetch material values reload the page');
      isSaveEnable = false;
    } finally {
      setIsLoading(false);
      setSaveEnabled(isSaveEnable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const save = useCallback(async () => {
    setIsLoading(true);
    for (let i = 0; i < optimizationTypes.length; i++) {
      const element = optimizationTypes[`${i}`];
      if (element.checked) {
        const {
          waste_min,
          optimization_level,
          minimize_sheet_rotation,
          book_cut,
        } = element;
        if (isNaN(waste_min)) {
          toastError(
            'Invalid input values. Please provide non-negative numbers, refresh the page',
          );
          return;
        }
        try {
          await updateOrgConfigs(element.key, {
            minimize_sheet_rotation,
            book_cut,
            waste_min,
            optimization_level,
          });
        } catch (error) {
          toastError('unable to update default  values data is incorrect');
        }
      }
    }
    try {
      await updateOrgConfigs(
        OPTIMIZATION_TYPE_CONFIG,
        optimizationTypes.filter(e => e.checked).map(e => e.optimized),
      );
    } catch (error) {
      toastError('unable to update opt type data is incorrect');
    }
    try {
      await updateOrgConfigs(
        CUTSMART_MATERIAL_ITEM_GRAIN_VAL_SKIP,
        grainValueSkip,
      );
    } catch (error) {
      toastError('unable to update grainValue');
    }
    try {
      const req_payload = {
        org_item_id: overhang?.uuid,
        feature_code: 'OVERHANG',
        name: 'OVERHANG',
        value: {
          val: parseFloat(overhang?.definition?.OVERHANG?.val),
        },
      };
      await createFeatureItem(overhang?.uuid, req_payload);
    } catch (error) {
      toastError('unable to update overhang');
    }
    try {
      if (
        (formData.template0 && !formData.field_name0) ||
        (formData.template1 && !formData.field_name1)
      ) {
        return;
      }
      const selectedValues = selectedItems.map(item => item.value);
      const cleanedTemplates = [formData.template0, formData.template1]
        .map(template => (template ? extractPlaceholders(template) : []))
        .flat();

      const otherSelectedValues = selectedValues.filter(
        value => !cleanedTemplates.includes(value),
      );

      const updatedValue = {
        fields: [
          {
            field_name: formData.field_name0,
            template: formData.template0,
          },
          {
            field_name: formData.field_name1,
            template: formData.template1,
          },
        ],
        features: [
          ...otherSelectedValues,
          ...cleanedTemplates
            .flat()
            .filter(template => selectedValues.includes(template)),
        ],
      };
      if (JSON.stringify(formData) !== JSON.stringify(initialFormData)) {
        await updateOrgConfigs(CUTSMART_ITEM_FEATURES_TEMPLATE, updatedValue);
      }
    } catch (error) {
      toastError('pattern img selector its not update');
    }
    function areValidValues(...values) {
      return values.every(value => !isNaN(value) && value > 0);
    }
    const { kerf, trim, default_length, default_width, code, uuid } = material;
    if (areValidValues(kerf, trim, default_length, default_width)) {
      try {
        await createItem({
          code,
          uuid,
          feature_defaults: {
            KERF: kerf,
            TRIM: trim,
            DEFAULT_LENGTH: default_length,
            DEFAULT_WIDTH: default_width,
          },
        });
      } catch (error) {
        toastError('unable to update material values the data is incorrect');
      }
      setIsLoading(false);
      return;
    }
    toastError(
      'Invalid input values. Please provide non-negative numbers. Refresh the page.',
    );
    setIsLoading(false);
  }, [
    formData,
    grainValueSkip,
    initialFormData,
    material,
    optimizationTypes,
    overhang?.definition?.OVERHANG?.val,
    overhang?.uuid,
    selectedItems,
  ]);
  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);
  const preventCopyItem = selectedItem => {
    if (!selectedItems.some(item => item.key === selectedItem.key)) {
      setSelectedItems(prevSelectedItems => [
        ...prevSelectedItems,
        selectedItem,
      ]);
    }
  };

  const ItemsFilter = ({ labelText, itemsList, setItemsList }) => {
    const itemFilter = useCallback(
      async searchTerm => {
        try {
          let filteredItems = [];
          if (searchTerm) {
            filteredItems = itemsList.filter(e =>
              e?.value?.toLowerCase()?.includes(searchTerm.toLowerCase()),
            );
          } else if (!itemsList?.length) {
            const itemBody = 'PART';
            const response = await getItemByCode(itemBody);
            const itemBody2 = 'EDGEBAND';
            const overhangResp = await getItemByCode(itemBody2);
            if (overhangResp.status === 200) {
              setOverhang(overhangResp.payload[0]);
            }
            if (response.status === 200) {
              const payload = response.payload[0];
              setItemsList(
                Object.keys(payload.definition).map(e => ({
                  key: e,
                  value: e,
                })),
              );
              filteredItems = Object.keys(payload.definition).map(e => ({
                key: e,
                value: e,
              }));
            } else {
              return itemsList;
            }
          } else {
            return itemsList;
          }
          return filteredItems;
        } catch (error) {
          toastError('request error');
          return itemsList;
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );
    return (
      <Select
        label={labelText}
        getOptions={searchTerm => itemFilter(searchTerm)}
        labelField='value'
        valueField='key'
        onSelect={preventCopyItem}
        search
      />
    );
  };

  // useEffect(() => {
  //   setItemsList(prevItemsList => [...prevItemsList]);
  // }, [setItemsList]);

  const hasSettingsAccess = useCallback(() => {
    return !!checkPermission('cutsmart', 'settings');
  }, []);
  console.log('Has settings access', hasSettingsAccess());
  const isDisabled = useCallback(() => {
    if (!hasSettingsAccess()) {
      return true;
    }
  }, [hasSettingsAccess]);
  useEffect(() => {
    if (!hasSettingsAccess()) {
      toastError('You do not have permission to change settings');
    }
  }, [hasSettingsAccess]);
  const history = useHistory();
  const goBack = useCallback(() => {
    history.push('/app/cutsmart');
  }, [history]);
  return (
    <Fragment>
      <div className='overflow-x-hidden cs-body h-100 w-100'>
        <div className='d-flex mt-2'>
          <div
            style={{ paddingLeft: '20px' }}
            className={'cursor-pointer'}
            onClick={goBack}
          >
            <svg
              width='32'
              height='28'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 19L16.41 17.59L11.83 13L10.8294 12.0357L11.83 11L16.42 6.41L15 5L8 12L15 19Z'
                fill='black'
              />
            </svg>
          </div>
          <div className='page-title d-flex align-items-center'>
            CUT SMART SETTINGS
          </div>
        </div>
        <div className='pt-4 ps-5 cs-fontstyl1'>Optimization Settings</div>
        <Form>
          <ScreenLoader />
          <div className='container-fluid'>
            {/* <div className='row'> */}
            <div className='cs-box pt-1 col-12 col-sm-12 col-md-11 col-lg-10 col-xl-8 col-xxl-8'>
              <div className='row'>
                <div className=' col-md-6 col-sm-6 col-xxl-5 d-flex my-2'>
                  <Form.Group
                    className={`form-group mb-1 cs-box-width ${
                      !material.trim ? 'has-error' : ''
                    }`}
                  >
                    <FormLabel className='cs-label'>TRIM</FormLabel>
                    <Form.Control
                      type='text'
                      placeholder='Enter Default Trim'
                      autoComplete='off'
                      disabled={materialState || isDisabled()}
                      value={material.trim}
                      onChange={handleInputChange('trim')}
                      isInvalid={
                        isNaN(parseFloat(material.trim)) ||
                        parseFloat(material.trim) < 0
                      }
                      className='cs-form-controls'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Enter a valid number for TRIM.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <span className='cs-info-icon'>
                    <button
                      style={{ padding: '12px', visibility: 'hidden' }}
                    ></button>
                  </span>
                </div>
                <div className='col-md-6 col-sm-6 col-lg-6 col-xl-6 col-xxl-5 d-flex my-2 mb-2'>
                  <Form.Group
                    className={`form-group mb-1 cs-box-width ${
                      !material.kerf ? 'has-error' : ''
                    }`}
                  >
                    <FormLabel className='cs-opt-label'>KERF</FormLabel>
                    <Form.Control
                      type='text'
                      placeholder='Enter Default Kerf'
                      autoComplete='off'
                      className='cs-form-controls'
                      disabled={materialState || isDisabled()}
                      value={material.kerf}
                      onChange={handleInputChange('kerf')}
                      isInvalid={
                        isNaN(parseFloat(material.kerf)) ||
                        parseFloat(material.kerf) < 0
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      Enter a valid number for Kerf.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <span className='info-icon'>
                    <button
                      style={{ padding: '12px', visibility: 'hidden' }}
                    ></button>
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-6 col-md-6 col-xxl-5 d-flex my-2'>
                  <Form.Group
                    className={`form-group mb-1 cs-box-width ${
                      !material.default_length ? 'has-error' : ''
                    }`}
                  >
                    <FormLabel className='cs-opt-label'>
                      DEFAULT LENGTH
                    </FormLabel>
                    <Form.Control
                      type='text'
                      placeholder='Enter Default length'
                      autoComplete='off'
                      className='cs-form-controls'
                      disabled={materialState || isDisabled()}
                      value={material.default_length}
                      onChange={handleInputChange('default_length')}
                      isInvalid={
                        isNaN(parseFloat(material.default_length)) ||
                        parseFloat(material.default_length) < 0
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      Enter a valid number for Default-Length.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <span className='cs-info-icon'>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={props => (
                        <Tooltip {...props}>
                          <span className='cs-tooltips'>
                            <IconInfoCircledWhite className='cs-tooltipColor' />{' '}
                            DEFAULT LENGTH
                            <article className='cs-textS'>
                              Default Length to be considered for a new material
                            </article>
                          </span>
                        </Tooltip>
                      )}
                      placement='bottom'
                    >
                      <button
                        disabled
                        className='cs-dis-btn'
                        style={{ backgroundColor: 'white' }}
                      >
                        <IconInfoCircled />
                      </button>
                    </OverlayTrigger>
                  </span>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-5 d-flex my-2'>
                  <Form.Group
                    className={`form-group mb-1 cs-box-width ${
                      !material.default_width ? 'has-error' : ''
                    }`}
                  >
                    <FormLabel className='cs-opt-label'>
                      DEFAULT WIDTH
                    </FormLabel>
                    <Form.Control
                      type='text'
                      placeholder='Enter Default width '
                      autoComplete='off'
                      disabled={materialState || isDisabled()}
                      value={material.default_width}
                      className='cs-form-controls'
                      onChange={handleInputChange('default_width')}
                      isInvalid={
                        isNaN(parseFloat(material.default_width)) ||
                        parseFloat(material.default_width) < 0
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      Enter a valid number for Default-Width.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <span className='cs-info-icon'>
                    <OverlayTrigger
                      delay={{ hide: 450, show: 300 }}
                      overlay={props => (
                        <Tooltip {...props}>
                          <span className='cs-tooltips'>
                            <IconInfoCircledWhite className='cs-tooltipColor' />{' '}
                            DEFAULT WIDTH
                            <article className='textS'>
                              Default Width to be considered for a new material
                            </article>
                          </span>
                        </Tooltip>
                      )}
                      placement='bottom'
                    >
                      <button
                        disabled
                        className='cs-dis-btn'
                        style={{ backgroundColor: 'white' }}
                      >
                        <IconInfoCircled />
                      </button>
                    </OverlayTrigger>
                  </span>
                </div>
                {overhang && (
                  <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-5 d-flex my-2'>
                    <Form.Group
                      className={`form-group mb-1 cs-box-width ${
                        !material.default_width ? 'has-error' : ''
                      }`}
                    >
                      <FormLabel className='cs-opt-label'>OVERHANG</FormLabel>
                      <Form.Control
                        type='text'
                        placeholder='Enter Default width '
                        autoComplete='off'
                        disabled={materialState || isDisabled()}
                        value={overhang?.definition?.OVERHANG?.val}
                        className='cs-form-controls'
                        onChange={e =>
                          setOverhang(prevState => ({
                            ...prevState,
                            definition: {
                              ...prevState.definition,
                              OVERHANG: {
                                ...prevState.definition.OVERHANG,
                                val: e.target.value,
                              },
                            },
                          }))
                        }
                        isInvalid={overhang < 0}
                      />
                      <Form.Control.Feedback type='invalid'>
                        Enter a valid number for Overhang.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <span className='cs-info-icon'>
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={props => (
                          <Tooltip {...props}>
                            <span className='cs-tooltips'>
                              <IconInfoCircledWhite className='cs-tooltipColor' />{' '}
                              OVERHANG
                              <article className='textS'>
                                Overhang to be considered for a new Edageband
                              </article>
                            </span>
                          </Tooltip>
                        )}
                        placement='bottom'
                      >
                        <button
                          disabled
                          className='cs-dis-btn'
                          style={{ backgroundColor: 'white' }}
                        >
                          <IconInfoCircled />
                        </button>
                      </OverlayTrigger>
                    </span>
                  </div>
                )}
              </div>

              <div className='row '>
                <div className='col-xxl-10 mt-1'>
                  {optimizationTypes.map((item, i) => (
                    <div
                      key={i}
                      className='cs-fieldset1 my-2 mb-3'
                      style={{
                        height: !item.checked ? '30px' : 'auto',
                      }}
                    >
                      <span className='d-flex justify-content-between ps-1 cs-fontstyl'>
                        {item.title}
                        <Form.Check
                          type='switch'
                          id='custom-switch'
                          checked={item.checked}
                          className={!material.uuid ? 'cs-disabled-switch' : ''}
                          // disabled={!material.uuid}
                          onChange={e => {
                            hasSettingsAccess() &&
                              handleFieldChange(i, 'checked', !item.checked);
                          }}
                        />
                      </span>
                      {item.checked && (
                        <div>
                          <FormCutsmartSetting
                            disabled={materialState || isDisabled()}
                            optimization_level={item.optimization_level}
                            onChangeOptimization_level={e =>
                              handleFieldChange(
                                i,
                                'optimization_level',
                                +e.target.value,
                              )
                            }
                            waste_min={item.waste_min}
                            onChangeWaste_min={e => {
                              const newValue =
                                !isNaN(e.target.value) && e.target.value !== ''
                                  ? +e.target.value
                                  : '';
                              handleFieldChange(i, 'waste_min', newValue);
                            }}
                            book_cut={item.book_cut === true}
                            onCheckboxBook_cut={e =>
                              handleFieldChange(i, 'book_cut', e.target.checked)
                            }
                            minimize_sheet_rotation={
                              item.minimize_sheet_rotation
                            }
                            onCheckboxMinimize_sheet_rotation={e =>
                              handleFieldChange(
                                i,
                                'minimize_sheet_rotation',
                                e.target.checked,
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  <div className='cs-fieldset1 pt-2 my-1 mb-3'>
                    Pattern Image Settings
                    <div
                      className='d-flex justify-content-between pt-4 pl-3 ps-1 cs-fontstyl'
                      style={{ fontSize: '15px', width: '350px' }}
                    >
                      Select Features
                      <span className='text-primary cursor-pointer'>
                        {!isDisabled() && (
                          <ItemsFilter
                            labelText='Add+'
                            itemsList={itemsList}
                            setItemsList={setItemsList}
                          />
                        )}
                      </span>
                    </div>
                    <div>
                      <div className='d-flex flex-wrap'>
                        {selectedItems &&
                          selectedItems.length > 0 &&
                          selectedItems.map((row, index) => {
                            // const keys = Object.keys(row);
                            return (
                              <div
                                key={index}
                                className='filter-btns mb-1'
                                style={{
                                  border: '1px solid',
                                  color: '#027AFF !important',
                                  display: 'flex',
                                }}
                              >
                                <div style={{ marginRight: '12px' }}>
                                  {row.value}
                                </div>
                                <div>
                                  <img
                                    src={BlackClose}
                                    alt=''
                                    onClick={() => {
                                      !isDisabled() &&
                                        setSelectedItems(prevItem =>
                                          prevItem.filter(
                                            (_, i) => i !== index,
                                          ),
                                        );
                                    }}
                                  ></img>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className='row'>
                      {[0, 1].map(index => (
                        <div key={index} className='col-md-12 d-flex my-2'>
                          <div className='col-md-6 col-sm-6 col-xxl-5'>
                            <Form.Group
                              className={`form-group mb-1 cs-box-width2`}
                            >
                              <FormLabel className='cs-label'>
                                FIELD NAME {index + 1}
                              </FormLabel>
                              <Form.Control
                                type='text'
                                placeholder='Enter Field Name'
                                autoComplete='off'
                                value={formData[`field_name${index}`]}
                                onChange={formHandleInput}
                                className='cs-form-controls'
                                name={`field_name${index}`}
                                disabled={isDisabled()}
                              />
                            </Form.Group>
                          </div>
                          <div
                            // eslint-disable-next-line sonarjs/no-nested-template-literals
                            className={`col-md-6 col-sm-6 col-xxl-5`}
                          >
                            <Form.Group
                              className={`form-group mb-1 cs-box-width2  ${
                                formData[`template${index}`] &&
                                blockingTemplates.length > 0
                                  ? 'has-error'
                                  : ''
                              }`}
                            >
                              <FormLabel className='cs-opt-label'>
                                TEMPLATE
                              </FormLabel>
                              <Form.Control
                                type='text'
                                placeholder='e.g {{PART_NAME}} - {{REMARKS}}'
                                autoComplete='off'
                                className='cs-form-controls'
                                value={formData[`template${index}`]}
                                onChange={formHandleInput}
                                name={`template${index}`}
                                isInvalid={
                                  formData[`template${index}`] &&
                                  blockingTemplates.length > 0
                                    ? 'has-error'
                                    : ''
                                }
                                disabled={isDisabled()}
                              />
                              <Form.Control.Feedback type='invalid'>
                                compare templates value with features
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='ps-5 mt-4 cs-fontstyl'>Additional Settings</div>
            <div className='cs-skip-check'>
              <div className='d-flex'>
                <input
                  type='checkbox'
                  className='cs-checkForm cursor-pointer'
                  disabled={materialState || isDisabled()}
                  checked={grainValueSkip}
                  onChange={e => {
                    setGrainValueSkip(e.target.checked);
                  }}
                />
                <Form.Check.Label className='mt-2 ps-1 cs-fonts'>
                  Skip Grain Validation
                </Form.Check.Label>
                <span className='cs-info-icon'>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={props => (
                      <Tooltip {...props}>
                        <span className='cs-tooltips'>
                          <IconInfoCircledWhite className='cs-tooltipColor' />
                          SKIP GRAIN VALIDATION
                          <article className='cs-textS'>
                            Skips validation of Grain at a part level
                          </article>
                        </span>
                      </Tooltip>
                    )}
                    placement='top'
                  >
                    <button
                      disabled
                      className='cs-dis-btn'
                      style={{ backgroundColor: 'white' }}
                    >
                      <IconInfoCircled />
                    </button>
                  </OverlayTrigger>
                </span>
              </div>
            </div>
            <div className='cs-cut-save-button'>
              <span>
                <Button
                  variant='primary'
                  disabled={!saveEnabled || isLoading || isDisabled()}
                  onClick={save}
                >
                  Save
                  {isLoading && <ScreenLoader force={isLoading} />}
                </Button>
              </span>
              <span>
                <Button
                  variant='outline-primary'
                  disabled={!saveEnabled || isLoading}
                  onClick={goBack}
                >
                  Cancel
                </Button>
              </span>
            </div>
          </div>
        </Form>
        <ToastContainer />
      </div>
    </Fragment>
  );
};

export default CutSmartSetting;
