import service from './index';
import { getState } from '../store';
import PropTypes from 'prop-types';

export const fetchConnectedPrinters = async filter => {
  const {
    user: { org, branch },
  } = getState();
  filter = filter ? filter : {};
  filter.org_id = org.uuid;
  filter.branch_id = branch && branch.branch_id ? [branch.branch_id] : [];
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/printer/filter`,
    data: {
      org_id: org.uuid,
      req_payload: filter,
    },
  });
};

export const OrgUserConfigTypes = {
  USER_DASHBOARDS: 'USER_DASHBOARDS',
  ITEM_DISPLAY_FIELDS: 'ITEM_DISPLAY_FIELDS',
};
export const OrgConfig = {
  TRACK_TRACE_REJECT_REASONS: 'TRACK_TRACE_REJECT_REASONS',
};

export const getOrgUserConfigs = config_type => {
  const {
    user: { org, user_id },
  } = getState();
  const token = getState().token;
  if (!org || !org.uuid || !token) {
    return new Promise((resolve, reject) => {
      reject({ status: 401, err: { err: 'User not logged in' } });
    });
  }

  return service({
    method: 'GET',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/userconfig/fetch/${user_id}/${config_type}`,
    data: {},
  });
};

export const getOrgUserConfig = (config_type, config_key) => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'GET',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/userconfig/fetch/${user_id}/${config_type}/${config_key}`,
    data: {},
  });
};

getOrgUserConfig.PropTypes = {
  config_type: PropTypes.string.isRequired,
  config_key: PropTypes.string.isRequired,
};

export const updateOrgUserConfig = (config_type, config_key, config_val) => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/userconfig/create`,
    data: {
      active: true,
      approved: true,
      deleted: false,
      user_id: user_id,
      org_id: org.uuid,
      req_payload: {
        app_user_id: user_id,
        config_type: config_type,
        config_key: config_key,
        config_val: config_val,
      },
    },
  });
};

export const deleteOrgUserConfig = uuid => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/userconfig/${user_id}/${uuid}?user_id=${user_id}`,
    data: {},
  });
};

updateOrgUserConfig.PropTypes = {
  config_type: PropTypes.string.isRequired,
  config_key: PropTypes.string.isRequired,
  config_val: PropTypes.object.isRequired,
};

export const getOrgConfig = config_key => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/config/key/${config_key}`,
    data: {},
  });
};

export const getOrgConfigs = config_keys => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/config/filter`,
    data: {
      req_payload: {
        deleted: [false],
        key: config_keys,
      },
    },
  });
};

export const updateOrgConfigs = (config_keys, val) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-org-service/v1/naadi/org/${org.uuid}/config/create`,
    data: {
      req_payload: {
        key: config_keys,
        value: {
          val: val,
        },
      },
    },
  });
};
export const getOrgList = data => {
  const {
    user: { org },
  } = getState();
  if (!org) {
    return new Promise((resolve, reject) => {
      reject({ status: 401, err: { err: 'User not logged in' } });
    });
  }
  data.org_id = org?.uuid;
  if (!data.req_payload) {
    data.req_payload = {};
  }
  if (!data.req_payload.deleted) {
    data.req_payload.deleted = [false];
  }
  return service({
    method: 'POST',
    url: `/naadi-org-service/v1/naadi/org/orgs/filter`,
    data,
  });
};

export const updateOrg = data => {
  const {
    user: { org, user_id },
  } = getState();
  if (data) {
    data.maintainer_org_id = org.uuid;
    data.user_id = user_id;
  }
  if (!data.req_payload) {
    data.req_payload = {};
  }
  data.req_payload.maintainer_org_id = org.uuid;
  return service({
    method: 'POST',
    url: `/naadi-org-service/v1/naadi/org/orgs/update`,
    data,
  });
};

export const copyOrg = orgId => {
  const {
    user: { org },
  } = getState();

  return service({
    method: 'PUT',
    url: `/naadi-org-service/v1/naadi/org/orgs/copy/${org.uuid}/${orgId}`,
    data: {},
  });
};

export const getModules = () => {
  return service({
    method: 'GET',
    url: `/api/naadi-auth-service/v1/naadi/auth/modules/fetch?page=0&size=1000`,
    data: {},
  });
};

export const activateOrgModulesAfterCreate = data => {
  return service({
    method: 'POST',
    url: `/api/naadi-auth-service/v1/naadi/auth/${data.org_id}/orgmodules/update`,
    data,
  });
};

export const getOrgModules = orgId => {
  if (!orgId) {
    const {
      user: { org },
    } = getState();
    orgId = org.uuid;
  }
  return service({
    method: 'GET',
    url: `/api/naadi-auth-service/v1/naadi/auth/${orgId}/orgmodules/fetch?page=0&size=1000`,
    data: {},
  });
};
export const createOrgBranch = data => {
  return service({
    method: 'POST',
    url: `/naadi-org-service/v1/naadi/org/${data.org_id}/branch/update`,
    data,
  });
};
export const getOrgBranches = org_id => {
  return service({
    method: 'POST',
    url: `/naadi-org-service/v1/naadi/org/${org_id}/branch/filter`,
    data: {
      page: 0,
      size: 100,
      req_payload: {
        deleted: [false],
      },
    },
  });
};

export const getTempImageURL = data => {
  return service({
    method: 'POST',
    url: `/naadi-app/api/fileupload/org/tempfile`,
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const uploadOrgImage = (orgId, data) => {
  return service({
    method: 'PUT',
    url: `/naadi-org-service/v1/naadi/org/orgs/image/update/${orgId}`,
    data,
  });
};
