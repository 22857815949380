/* eslint-disable security/detect-object-injection */
import React, { useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import '../../scss/components/_dropdown.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ScreenLoader from '../../components/common/screen-loader';
import useMobile from '../../hooks/useMobile';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import {
  createStation,
  fetchStations,
  deleteStation,
} from '../../services/station';

import { toastError, toastMessage } from '../../helpers/packing/packingHelper';
import StationHeader from './stationheader';
import BottomDrawerContent from './bottom-drawer-menu';
const Class_RECT = 'rectangle-input';
const Class_RECT_Highlight = 'rectangle-input highlight';
const Class_RECT_Error = 'rectangle-input error';
// eslint-disable-next-line sonarjs/cognitive-complexity
const NewStation = () => {
  const location = useLocation();
  const [nameFocus, setNameFocus] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [codeFocus, setCodeFocus] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [stationsList, setStationsList] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [isMobile, isTab] = useMobile();
  const [stationUuid, setStationUuid] = useState('');
  const [stationName, setStationName] = useState();
  const [titleText, setTitleText] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  //const [stationId, setStationId] = useState();
  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const _params = Object.fromEntries(urlSearchParams.entries());
    setStationUuid(_params.uuid);
    // setParams(_params);
  }, [location, setStationUuid, stationUuid]);

  const [form, setForm] = useState({
    name: '',
    code: '',
  });

  const onChange = useCallback(e => {
    const target = e.target;
    setForm(o => ({ ...o, [target.getAttribute('name')]: target.value }));
  }, []);

  const FetchStation = useCallback(
    async tid => {
      const req = {
        page: 0,
        size: 100,
        req_payload: {
          deleted: [false],
          uuid: [stationUuid],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_STATION'));
        const fetchSt = await fetchStations(req);
        if (fetchSt && fetchSt.status !== 200) {
          return;
        }

        if (fetchSt && fetchSt.payload.length > 0) {
          setStationsList(fetchSt.payload[0]);

          setForm({
            name: fetchSt.payload[0].name,
            code: fetchSt.payload[0].station_code,
          });
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_STATION'));
      }
    },
    [dispatch, stationUuid],
  );
  useEffect(() => {
    FetchStation();
  }, [FetchStation]);

  const delStation = useCallback(
    async oid => {
      try {
        dispatch(actions.busy.add('DELETE_STATION'));
        const deleteDF = await deleteStation(oid);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Delete Station');
        }
        toastMessage({
          type: 'success',
          message: 'Station Removed Successfully',
        });
        FetchStation();
        history.goBack();
      } finally {
        dispatch(actions.busy.remove('DELETE_STATION'));
      }
    },
    [dispatch, FetchStation, history],
  );
  const markInactive = useCallback(
    async oid => {
      const req = {
        active: false,
        approved: true,
        deleted: false,
        req_payload: {
          uuid: oid,
        },
      };
      try {
        dispatch(actions.busy.add('MARK_INACTIVE'));
        const deleteDF = await createStation(req);
        if (deleteDF && deleteDF.status !== 200) {
          return toastError('Unable to Mark as Inactive');
        }
        toastMessage({
          type: 'success',
          message: 'Station Marked as Inactive',
        });
        FetchStation();
        history.goBack();
      } finally {
        dispatch(actions.busy.remove('MARK_INACTIVE'));
      }
    },
    [dispatch, FetchStation, history],
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const saveChanges = useCallback(async () => {
    if (form.name === '') {
      toastError('Please Enter Station Name');
      return;
    }
    try {
      dispatch(actions.busy.add('CREATE_STATION'));

      const data = {
        page: 0,
        size: 100,
        active: true,
        approved: true,
        deleted: false,
        req_payload: {
          name: form.name,
          station_code: form.code,
        },
      };
      console.log(data);
      if (stationUuid) {
        data.req_payload.uuid = stationUuid;
      }
      const createStRespFields = await createStation(data);
      if (createStRespFields.status !== 200) {
        toastError(
          createStRespFields.err && createStRespFields.err.err
            ? createStRespFields.err.err
            : 'Unable to create the Station',
        );
        return;
      }

      toastMessage({
        type: 'success',
        message: 'Created Successfully',
      });
      history.goBack();
    } finally {
      dispatch(actions.busy.remove('CREATE_STATION'));
    }
  }, [dispatch, form, history, stationUuid]);

  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />
      <BottomDrawerContent
        isMobile={isMobile}
        isTab={isTab}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        title={titleText}
        delStation={delStation}
        markInactive={markInactive}
        id={stationUuid}
      />
      <div>
        <div className='container-fluid position-relative px-0'>
          <StationHeader
            isMobile={isMobile}
            isTab={isTab}
            stationName={stationsList.name || stationName}
            delStation={delStation}
            markInactive={markInactive}
            setStationName={setStationName}
            titleText={titleText}
            setTitleText={setTitleText}
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            stationUuid={stationUuid}
            setStationId={setStationUuid}
          />
          <div style={{ height: '22px' }}></div>
          <Form style={{ padding: '0px' }}>
            <div
              className={
                nameError
                  ? Class_RECT_Error
                  : nameFocus // eslint-disable-next-line prettier/prettier
                    ? Class_RECT_Highlight // eslint-disable-next-line prettier/prettier
                    : Class_RECT
              }
              style={{
                margin: '0px',
                padding: '0px',
                marginBottom: '20px',
                marginLeft: '20px',
                paddingLeft: '10px',
              }}
            >
              <div>
                <label className='lbl-input lbl-padding'>Name</label>

                <Form.Control
                  id='name'
                  className='txt-input primary'
                  type='text'
                  autoComplete='off'
                  name='name'
                  placeholder='Enter name of station'
                  value={form.name}
                  onFocus={() => {
                    setNameFocus(true);
                    setNameError(false);
                  }}
                  onBlur={() => {
                    setNameFocus(false);
                  }}
                  onChange={onChange}
                />
              </div>
            </div>
            <div
              className={
                codeError
                  ? Class_RECT_Error
                  : codeFocus
                    ? Class_RECT_Highlight
                    : Class_RECT
              }
              style={{
                margin: '0px',
                padding: '0px',
                marginBottom: '20px',
                marginLeft: '20px',
                paddingLeft: '10px',
              }}
            >
              <div>
                <label className='lbl-input lbl-padding'>Code</label>
                <Form.Control
                  id='code'
                  className='txt-input primary'
                  type='text'
                  autoComplete='off'
                  name='code'
                  placeholder='Enter Code'
                  value={form.code}
                  onFocus={() => {
                    setCodeFocus(true);
                    setCodeError(false);
                  }}
                  onBlur={() => {
                    setCodeFocus(false);
                  }}
                  onChange={onChange}
                  style={{ pointerEvents: stationUuid ? 'none' : '' }}
                />
              </div>
            </div>
          </Form>

          <div
            style={{
              maxWidth: '320px',
              marginLeft: '20px',
              marginTop: '30px',
            }}
          >
            <div className='row'>
              <div className='col-9'>Workstations</div>
              <div className='col-3' style={{ color: '#006EED' }}>
                <div
                  onClick={() =>
                    history.push(`/app/workstations/newworkstation`)
                  }
                >
                  Add+
                </div>
              </div>
            </div>
            <div style={{ height: '20px' }}></div>

            <div
              style={{
                borderBottom: '1px solid',
                borderColor: '#C4C4C4',
                width: '320px',
              }}
            ></div>
          </div>

          <div className='d-flex'>
            <div className='floating-div'>
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{ marginRight: '12px', background: 'white' }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary btn-save-changes'
                onClick={() => {
                  saveChanges();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewStation;
