import service from './index';
import { getState } from '../store';
import moment from 'moment';
import { cloneDeep } from 'lodash';
const USER_NOT_FOUND = 'User Not Found';

export const getOrderExternalCodes = async (term, page, size) => {
  const {
    user: { org, branch },
  } = getState();
  if (!page && page !== 0) {
    page = 0;
  }
  if (!size || size <= 0) {
    size = 5;
  }
  const branchId = branch && branch.branch_id ? branch.branch_id : 'no-branch';

  const resp = await service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/ext_code/${branchId}?page=${page}&size=${size}&term=${term}`,
    data: {},
  });
  if (resp.status !== 200) {
    return resp;
  }
  const payload = resp.payload.map(val => {
    return {
      ext_code: val,
      branch_id: branchId,
      org_id: org.uuid,
    };
  });
  resp.payload = payload;
  return resp;
};
export const getOrderSecondaryCustomers = async (term, page, size) => {
  const {
    user: { org, branch },
  } = getState();
  if (!page && page !== 0) {
    page = 0;
  }
  if (!size || size <= 0) {
    size = 5;
  }
  const branchId = branch && branch.branch_id ? branch.branch_id : 'no-branch';

  const resp = await service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/secondary_customer/${branchId}?page=${page}&size=${size}&term=${term}`,
    data: {},
  });
  if (resp.status !== 200) {
    return resp;
  }
  const payload = resp.payload.map(val => {
    return {
      secondary_customer: val,
      branch_id: branchId,
      org_id: org.uuid,
    };
  });
  resp.payload = payload;
  return resp;
};
export const getOrderByFilter = (filter, page, size, sort) => {
  const {
    user: { org, user_id, branch },
  } = getState();
  if (!page && page !== 0) {
    page = 0;
  }
  if (!size || size <= 0) {
    size = 5;
  }
  if (!sort) {
    sort = [];
  }
  if (!filter.deleted) {
    filter.deleted = [false];
  }
  if (!filter.branch_id) {
    filter.branch_id = branch && branch.branch_id ? [branch.branch_id] : [];
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/filter`,
    data: {
      user_id,
      page: page,
      size: size,
      org_id: org.uuid,
      req_payload: filter,
      sort: sort,
    },
  });
};

export const getOrderByTerm = (
  term,
  has_children,
  orderType,
  additionalFilters,
) => {
  const {
    user: { org, user_id, branch },
  } = getState();
  if (!org || !org.uuid) {
    return new Promise(r => {
      r({ status: 401, payload: [] });
    });
  }
  const orderTypes = orderType ? [orderType] : [];
  let reqPayload = {
    term,
    has_children,
    branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
    deleted: [false],
    active: [true],
    order_type: orderTypes,
    not_status: ['HOLD', 'CLOSED'],
  };
  if (additionalFilters) {
    reqPayload = { ...reqPayload, ...additionalFilters };
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/filter`,
    data: {
      user_id,
      page: 0,
      size: 5,
      org_id: org.uuid,
      req_payload: reqPayload,
      sort: [
        {
          column: 'id',
          asc: false,
        },
      ],
    },
  });
};

export const getOrderItemByCode = code => {
  const {
    user: { org, user_id, branch },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/items/filter`,
    data: {
      user_id,
      page: 0,
      size: 5,
      org_id: org.uuid,
      req_payload: {
        code: [code],
        deleted: [false],
        released: [true],
        branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
        only_visible_features: true,
        fetch_order_item_status: true,
      },
    },
  });
};
export const getTrackTraceCtxItems = (ctxType, ctxRef, operationCodes) => {
  const user = getState().user;
  if (!user) {
    return new Promise((resolve, reject) => {
      reject(USER_NOT_FOUND);
    });
  }
  const { org, branch } = user;
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/items/processed/${branch.branch_id}/${ctxType}/${ctxRef}`,
    data: {
      req_payload: operationCodes,
    },
  });
};
export const getItemsWithFilterChunked = async (filter, chunk) => {
  let page = filter.page;
  const promiseList = [];
  for (let i = 0; i < chunk; i++) {
    const _filter = { ...filter };
    _filter.page = page;
    const p = getItemsWithFilter(_filter);
    promiseList.push(p);
    page++;
  }
  const resp = await Promise.all(promiseList);
  const errPayload = resp.filter(val => !val.status || val.status !== 200);
  if (errPayload.length > 0) {
    return errPayload[0];
  }
  const payload = {
    status: 200,
    meta_data: {
      orders: {},
      order_item_status: {},
      operation_status: {},
      last_modified: null,
    },
    payload: [],
  };
  resp.forEach(chunkResp => {
    if (chunkResp.payload.length === 0) {
      return;
    }
    payload.meta_data.last_modified = chunkResp.meta_data.last_modified;
    payload.meta_data.orders = {
      ...payload.meta_data.orders,
      ...chunkResp.meta_data.orders,
    };
    if (chunkResp.meta_data.order_item_status) {
      payload.meta_data.order_item_status = {
        ...payload.meta_data.order_item_status,
        ...chunkResp.meta_data.order_item_status,
      };
    }
    if (chunkResp.meta_data.operation_status) {
      payload.meta_data.operation_status = {
        ...payload.meta_data.operation_status,
        ...chunkResp.meta_data.operation_status,
      };
    }
    payload.payload = [...payload.payload, ...chunkResp.payload];
  });
  return payload;
};
export const getItemsWithFilter = async (filter, page, size) => {
  const user = getState().user;
  if (!user) {
    return new Promise((resolve, reject) => {
      reject(USER_NOT_FOUND);
    });
  }
  const { org, branch } = user;
  if (!filter) {
    filter = {
      req_payload: {},
    };
  }
  if (!filter.org_id) {
    filter.org_id = org.uuid;
  }
  filter.req_payload.branch_id =
    branch && branch.branch_id ? [branch.branch_id] : [];
  filter.page = page ? page : filter.page ? filter.page : 0;
  if (size) {
    filter.size = size;
  }
  const orderItemsResp = await service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/items/filter?light=true`,
    data: filter,
  });
  if (orderItemsResp.status === 200) {
    const metaData = orderItemsResp.meta_data || {};
    const orders = metaData.orders || {};

    const payload = orderItemsResp.payload;
    orderItemsResp.payload = payload.map(val => {
      if (orders[`${val.order_id}`]) {
        val.order = cloneDeep(orders[`${val.order_id}`]);
      }
      if (!val.operation_checklist) {
        val.operation_checklist = [];
      }
      val.operation_pending = val.operation_checklist.filter(
        c => c.mandatory === true,
      ).length;
      return val;
    });
  }
  return new Promise(resolve => {
    resolve(orderItemsResp);
  });
};
export const getOrderItemById = uuid => {
  const filter = {
    req_payload: {
      uuid: [uuid],
      //deleted: [false],
    },
  };
  return getItemsWithFilter(filter);
};
export const DEFAULT_ITEM_FEATURES = [
  'UOM',
  'MATERIAL',
  'SURFTOP',
  'SURFBOT',
  'parent_item_code',
  'BOXITEM',
  'INVENTORY_TYPE',
  'PART_NAME',
  'FIN_LENG',
  'FIN_WIDTH',
  'FIN_THK',
  'BOX_NO',
  'PACKING_GROUP',
  'TOP_DRILL',
  'BOTTOM_DRILL',
  'TOP_DRILL_ALT',
  'BOTTOM_DRILL_ALT',
  'LENGTH',
  'WIDTH',
  'SKIP_OPERATIONS',
];
export const getItems = (
  order_id,
  checklist_stations,
  skip_operation_check,
  size,
) => {
  const {
    user: { org, branch },
  } = getState();
  return getItemsWithFilter({
    page: 0,
    size: size ? size : 200,
    org_id: org.uuid,
    req_payload: {
      order_id: [order_id],
      released: [true],
      deleted: [false],
      checklist_stations: checklist_stations,
      skip_operation_check: skip_operation_check,
      only_visible_features: true,
      features: DEFAULT_ITEM_FEATURES,
      branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
      fetch_direct_shipped_qty: true,
    },
    sort: [
      {
        column: 'updated_on',
        asc: true,
      },
    ],
  });
};

export const fetchReqUuid = count => {
  const {
    user: { org },
  } = getState();
  count = count === undefined || isNaN(count) ? 200 : count;
  count = Math.max(20, count);
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${
      org.uuid
    }/scanrecords/references?count=${count}&${new Date().getTime()}`,
  });
};

export const createScanRecord = reqData => {
  const {
    user: { org, user_id, branch },
  } = getState();
  reqData.active = true;
  reqData.deleted = false;
  reqData.approved = true;
  if (reqData.user_id === undefined) {
    reqData.user_id = user_id;
  }
  if (reqData.org_id === undefined) {
    reqData.org_id = org.uuid;
  }
  const reqPayload = reqData.req_payload;
  if (reqPayload.scan_code === undefined || reqPayload.scan_code === null) {
    reqPayload.scan_code = '';
  }
  reqPayload.branch_id = branch && branch.branch_id ? branch.branch_id : '';
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${reqData.org_id}/scanrecords/create`,
    data: reqData,
  });
};

export const linkItemToBox = reqData => {
  const {
    user: { org, user_id },
  } = getState();
  if (reqData.user_id === undefined) {
    reqData.user_id = user_id;
  }
  if (reqData.org_id === undefined) {
    reqData.org_id = org.uuid;
  }
  if (reqData.active === undefined) {
    reqData.active = true;
  }
  if (reqData.approved === undefined) {
    reqData.approved = true;
  }
  if (reqData.deleted === undefined) {
    reqData.deleted = true;
  }

  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${reqData.org_id}/boxitems/linktobox`,
    data: reqData,
  });
};

export const delinkItemFromBox = reqData => {
  const {
    user: { org, user_id },
  } = getState();
  if (reqData.user_id === undefined) {
    reqData.user_id = user_id;
  }
  if (reqData.org_id === undefined) {
    reqData.org_id = org.uuid;
  }

  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${reqData.org_id}/boxitems/delinkfrombox`,
    data: reqData,
  });
};

export const getOrders = (
  filter_params,
  page_params,
  sortBy,
  isActive = true,
) => {
  const { user } = getState();
  if (!user || !user.org) {
    return new Promise(resolve => {
      resolve({ status: 1002, message: 'User not logged in' });
    });
  }
  let _sort = [{ column: 'created_on', asc: false }];
  if (sortBy && sortBy.length > 0) {
    _sort = sortBy;
  }
  const {
    user: { org, user_id, branch },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/filter`,
    data: {
      user_id,
      page: page_params?.page_no,
      size: page_params?.page_size,
      org_id: org.uuid,
      sort: _sort,
      req_payload: {
        org_id: org.uuid,
        fetch_entities: true,
        fetch_branch_detail: true,
        branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
        active: [isActive],
        ...filter_params,
      },
    },
  }).then(({ payload, ...rest }) => {
    return {
      ...rest,
      payload: (payload || []).map(o => ({
        ...o,
        customer_name: o.entity.name,
      })),
    };
  });
};

export const getLinkedOrders = orderId => {
  const { user } = getState();
  if (!user || !user.org) {
    return new Promise(resolve => {
      resolve({ status: 1002, message: 'User not logged in' });
    });
  }
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/linkedorder/order/${orderId}`,
    data: {},
  });
};

export const getOrdersDetails = id => {
  const {
    user: { org, user_id, branch },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/filter`,
    data: {
      user_id,
      page: 0,
      size: 50,
      org_id: org.uuid,
      req_payload: {
        org_id: org.uuid,
        fetch_entities: true,
        fetch_children: true,
        fetch_linked_orders: true,
        uuid: [id],
        branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
      },
    },
  });
};

export const saveNewOrder = payload => {
  const {
    user: { org, user_id },
  } = getState();
  if ((payload && !payload.delivery_date) || payload.delivery_date === '') {
    payload.delivery_date = null;
  } else if (payload && payload.delivery_date) {
    payload.delivery_date = moment(payload.delivery_date).toDate();
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/create`,
    data: {
      user_id,
      active: true,
      approved: true,
      org_id: org.uuid,
      req_payload: {
        org_id: org.uuid,
        ...payload,
      },
    },
  });
};

export const releaseHoldToProductionService = order_id => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/releasehold/${order_id}`,
    data: {},
  });
};

export const updateOrderService = (order_id, payload) => {
  const {
    user: { org, user_id },
  } = getState();
  if (!payload.delivery_date || payload.delivery_date === '') {
    payload.delivery_date = null;
  } else if (payload && payload.delivery_date) {
    payload.delivery_date = moment(payload.delivery_date).toDate();
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/create`,
    data: {
      user_id,
      org_id: org.uuid,
      req_payload: {
        uuid: order_id,
        org_id: org.uuid,
        ...payload,
      },
    },
  });
};

export const getEntities = (filter_params, page, size) => {
  const {
    user: { org, user_id },
  } = getState();
  if (!page) {
    page = 0;
  }
  if (!size) {
    size = 50;
  }
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-entity-service/v1/naadi/entity/${org.uuid}/orgentity/filter`,
    data: {
      user_id,
      page: 0,
      size: 50,
      org_id: org.uuid,
      req_payload: {
        ...filter_params,
      },
    },
  });
};

export const saveNewEntity = payload => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/naadi-app/api/naadi-entity-service/v1/naadi/entity/${org.uuid}/orgentity/`,
    data: {
      user_id,
      active: true,
      approved: true,
      deleted: true,
      org_id: org.uuid,
      req_payload: {
        org_id: org.uuid,
        ...payload,
      },
    },
  });
};

export const deleteOrderSrcService = uuid => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ordersrc/id/${uuid}`,
    data: {},
  });
};

export const recallOrderSrcService = uuid => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ordersrc/recall/${uuid}`,
    data: {},
  });
};

export const createOrderAttachmentService = (order_id, attachment) => {
  const {
    user: { org, user_id },
  } = getState();
  const payload = {
    active: true,
    approved: true,
    deleted: false,
    user_id: user_id,
    org_id: org.uuid,
    req_payload: {
      org_id: org.uuid,
      order_id: order_id,
      attachment_id: attachment.uuid,
      file_name: attachment.file_name,
    },
  };
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/attachment/create`,
    data: payload,
  });
};

export const deleteOrderAttachmentService = uuid => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/attachment/id/${uuid}?user_id=${user_id}`,
    data: {},
  });
};

export const serializeOrderSrc = async (uuid, release) => {
  const {
    user: { org, user_id },
  } = getState();
  const _release = release === true;
  const payload = {
    org_id: org.uuid,
    user_id: user_id,
    req_payload: {
      org_id: org.uuid,
    },
  };
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ordersrc/serialize/${uuid}?user_id=${user_id}&release=${_release}&sync=true`,
    data: payload,
  });
};

export const releaseOrderSrc = async uuid => {
  const {
    user: { org, user_id },
  } = getState();
  const payload = {
    org_id: org.uuid,
    user_id: user_id,
    req_payload: {
      org_id: org.uuid,
    },
  };
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orderitemsrc/ordersrc/${uuid}/release?user_id=${user_id}`,
    data: payload,
  });
};

export const linkOrderService = (order_id, linked_order_id) => {
  const {
    user: { org, user_id },
  } = getState();
  const payload = {
    org_id: org.uuid,
    user_id: user_id,
    active: true,
    approved: true,
    req_payload: {
      org_id: org.uuid,
      order_id: order_id,
      linked_order_id: linked_order_id,
    },
  };
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/linkedorder/create`,
    data: payload,
  });
};

export const deleteOrderLinkService = (order_id, linked_order_id) => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/linkedorder/order/${order_id}/${linked_order_id}?&user_id=${user_id}`,
    data: {},
  });
};

export const delinkParentOrder = order_id => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'DELETE',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/parentorder/${order_id}`,
    data: {},
  });
};

export const getOrdersDownloadFormats = () => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orderdownload/format/filter`,
    data: {
      user_id,
      page: 0,
      size: 50,
      org_id: org.uuid,
      req_payload: {
        org_id: org.uuid,
        name: [],
        deleted: [false],
      },
    },
  });
};

export const getOrderSrcClientService = filter => {
  const {
    user: { org },
  } = getState();
  filter.org_id = org.uuid;
  filter.req_payload.org_id = org.uuid;
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrcclient/filter`,
    data: filter,
  });
};

export const getOrderSrcFromClientService = (client_id, order_id) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrcclient/designtool/ordersrc/${client_id}/order/${order_id}`,
    data: {},
  });
};

export const retriggerOrderSrcEventService = order_src_id => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/job/retrigger/${order_src_id}?user_id=${user_id}`,
    data: {},
  });
};

export const getOrderSrcFormatDownloadUrlService = (
  order_src_id,
  format_id,
) => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/download/${order_src_id}?user_id=${user_id}&format_id=${format_id}`,
    data: {},
  });
};

//This will raise a new Event
export const retriggerOrderSrcEvent = order_src_id => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/job/retrigger/event/${order_src_id}?user_id=${user_id}`,
    data: {},
  });
};

export const retriggerOrderSrcPendingTaskEvents = order_src_id => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/job/pendingtasks/retrigger/${order_src_id}?user_id=${user_id}`,
    data: {},
  });
};

export const getPackingStatus = (itemType, fromDate, toDate) => {
  const {
    user: { org, user_id, branch },
  } = getState();
  fromDate = moment(fromDate).toISOString();
  toDate = moment(toDate).toISOString();
  const refDate = moment(new Date()).toISOString();
  const branchIds = (branch && branch.branch_id ? [branch.branch_id] : []).join(
    ',',
  );
  return service({
    method: 'GET',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/boxitems/packingstatus?user_id=${user_id}&item_type=${itemType}&from_date=${fromDate}&to_date=${toDate}&ref_date=${refDate}&branch_id=${branchIds}`,
    data: {},
  });
};

export const getOperationChecklistWithFilter = filter => {
  const user = getState().user;
  if (!user) {
    return new Promise((resolve, reject) => {
      reject(USER_NOT_FOUND);
    });
  }
  const { org } = user;
  if (!filter) {
    filter = {};
  }
  if (!filter.org_id) {
    filter.org_id = org.uuid;
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/operationchecklist/filter`,
    data: filter,
  });
};

export const printOrderItemLabels = reqdata => {
  const user = getState().user;
  if (!user) {
    return new Promise((resolve, reject) => {
      reject(USER_NOT_FOUND);
    });
  }
  const { org, branch } = user;
  const req = {
    org_id: org.uuid,
    req_payload: reqdata,
  };
  if (!reqdata) {
    req.req_payload = {};
  }
  if (branch && branch.branch_id) {
    req.req_payload.branch_id = branch.branch_id;
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/items/labelprint`,
    data: req,
  });
};

export const getGoogleDriveLink = order_src_id => {
  const user = getState().user;
  if (!user) {
    return new Promise((resolve, reject) => {
      reject(USER_NOT_FOUND);
    });
  }
  const { org } = user;

  return service({
    method: 'GET',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/editlink/${order_src_id}`,
    data: {},
  });
};

export const reUploadFromSheet = order_src_id => {
  const user = getState().user;
  if (!user) {
    return new Promise((resolve, reject) => {
      reject(USER_NOT_FOUND);
    });
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ordersrc/sheets/reupload/${order_src_id}`,
    data: {},
  });
};

export const addScanRecord = payload => {
  const { user } = getState();
  const { org, branch } = user || {};
  payload.branch_id = branch && branch.branch_id ? branch.branch_id : '';
  payload.org_id = org ? org.uuid : '';
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/scanrecords/create`,
    data: {
      org_id: org.uuid,
      req_payload: payload,
    },
  });
};

export const getScanRecords = filter => {
  const { user } = getState();
  const { org, branch } = user || {};
  filter.req_payload.branch_id =
    branch && branch.branch_id ? [branch.branch_id] : [];
  filter.req_payload.org_id = org ? org.uuid : '';
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/scanrecords/filter`,
    data: filter,
  });
};

export const updateOperationStatus = payload => {
  const { user } = getState();
  const { org, branch } = user || {};
  payload.branch_id = branch && branch.branch_id ? branch.branch_id : '';
  payload.org_id = org ? org.uuid : '';
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstatus/update`,
    data: payload,
  });
};

export const getBulkOperationStatusErrMsg = errs => {
  if (!errs || errs.length === 0) {
    return '';
  }
  return errs
    .map(err => {
      if (!err) {
        return '';
      }
      if (!err.code) {
        return err + '';
      }
      const errMsg = err.err;
      const errAdd = err.err_additional;
      if (errAdd && errAdd !== null && errAdd !== errMsg) {
        return errMsg + ' ' + errAdd;
      }
      return errMsg;
    })
    .join(',');
};
export const updateBulkOperationStatus = (payload, collaborator) => {
  const { user } = getState();
  const { org } = user || {};
  let url = `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstatus/update/bulk`;
  if (collaborator && collaborator.org_id && collaborator.uuid) {
    url = `/api/naadi-order-service2/v1/naadi/order2/${collaborator.org_id}/operationstatus/update/bulk/collaborator/${collaborator.uuid}`;
  }
  return service({
    method: 'POST',
    url: url,
    data: payload,
  });
};
export const executeMachineSync = (stationId, orderItemId, scanCode) => {
  const { user } = getState();
  const { org } = user || {};
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/operationstatus/machinesync/${stationId}/${orderItemId}/${scanCode}`,
    data: {},
  });
};

export const getOperationStatus = filter => {
  const { user } = getState();
  const { org, branch } = user || {};
  if (!filter.req_payload) {
    filter.req_payload = {};
  }
  filter.req_payload.branch_id =
    branch && branch.branch_id ? [branch.branch_id] : [];
  filter.org_id = org ? org.uuid : '';
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstatus/filter`,
    data: filter,
  });
};
export const getRecentScans = (
  pod,
  operationCodes,
  statusList,
  processedOn,
) => {
  const { user } = getState();
  const { org, branch } = user || {};

  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const operationCode =
    operationCodes && operationCodes.length > 0 ? operationCodes.join(',') : '';
  const processedOnEpoch = new Date(processedOn).getTime();
  const status =
    statusList && statusList.length > 0 ? statusList.join(',') : '';
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstatus/processed/${branchId}/${pod}?operation_code=${operationCode}&processed_on=${processedOnEpoch}&status=${status}`,
  });
};
export const getOrderIndexJobsByOrder = (orderId, page, size) => {
  const { user } = getState();
  const { org } = user || {};
  if (!page) {
    page = 0;
  }
  if (!size) {
    page = 20;
  }
  return service({
    method: 'GET',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/orderupload/indexjobs/order/${orderId}?page=${page}&size=${size}`,
  });
};
export const getOrderIndexJobsByJobId = (jobId, page, size) => {
  const { user } = getState();
  const { org } = user || {};
  if (!page) {
    page = 0;
  }
  if (!size) {
    page = 20;
  }
  return service({
    method: 'GET',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/orderupload/indexjobs/job/${jobId}?page=${page}&size=${size}`,
  });
};
export const getOrderIndexJobTasksByImportJob = (jobId, page, size) => {
  const { user } = getState();
  const { org } = user || {};
  page = !page ? 0 : page;
  size = !size ? 20 : size;
  return service({
    method: 'GET',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/orderupload/import/task/${jobId}?page=${page}&size=${size}`,
    data: {},
  });
};
export const getOrderIndexJobTasksByIndexJob = (jobId, page, size) => {
  const { user } = getState();
  const { org } = user || {};
  page = !page ? 0 : page;
  size = !size ? 20 : size;
  return service({
    method: 'GET',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/orderupload/indexjobs/task/${jobId}?page=${page}&size=${size}`,
    data: {},
  });
};
export const retriggerOrderIndexUploadJob = jobId => {
  const { user } = getState();
  const { org } = user || {};

  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/orderupload/retrigger/${jobId}`,
    data: {},
  });
};
export const cancelOrderBulkImportUploadJob = jobId => {
  const { user } = getState();
  const { org } = user || {};

  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/import/cancel/${jobId}`,
    data: {},
  });
};
export const cancelOrderIndexUploadJob = jobId => {
  const { user } = getState();
  const { org } = user || {};

  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/orderupload/cancel/${jobId}`,
    data: {},
  });
};

export const createOrderIndexUploadJob = (orderId, attachmentId) => {
  const { user } = getState();
  const { org } = user || {};

  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/orderupload/${orderId}`,
    data: {
      req_payload: {
        attachment_id: attachmentId,
      },
    },
  });
};
export const createOrderBulkImportJob = attachmentId => {
  const { user } = getState();
  const { org, branch } = user || {};
  return service({
    method: 'POST',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/import`,
    data: {
      req_payload: {
        attachment_id: attachmentId,
        branch_id: branch && branch.branch_id ? branch.branch_id : '',
      },
    },
  });
};

export const retriggerOrderBulkImportJob = jobId => {
  const { user } = getState();
  const { org } = user || {};

  return service({
    method: 'PUT',
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/orders/bulk/retrigger/${jobId}`,
    data: {},
  });
};

export const getOrderImportJobs = (filter, size, page) => {
  const { user } = getState();
  const { org } = user || {};
  filter = filter ? filter : {};
  const req = {
    req_payload: filter ? filter : {},
    page: page,
    size: size,
    sort: [
      {
        column: 'id',
        asc: false,
      },
    ],
  };
  req.req_payload.consolidate = true;
  req.req_payload.deleted = [false];
  req.req_payload.job_type = ['ORDER_BULK_UPLOAD_JOB'];
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/bulk/import/jobs/filter`,
    data: req,
  });
};

export const getOperationPipeline = operations => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';

  const operationCodes = operations
    ? operations.sort((a, b) => a.localeCompare(b)).join(',')
    : '';
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/stat/pipeline/${branchId}?operation_codes=${operationCodes}`,
    data: {},
  });
};
const getBreadcrumbFilter = breadcrumb => {
  let filter = '';
  if (breadcrumb && breadcrumb.filter) {
    if (breadcrumb.filter.order_id) {
      filter += '&order_id=' + breadcrumb.filter.order_id;
    }
    if (breadcrumb.filter.entity_id) {
      filter += '&entity_id=' + breadcrumb.filter.entity_id;
    }
    if (breadcrumb.filter.parent_order_id) {
      filter += '&parent_order_id=' + breadcrumb.filter.parent_order_id;
    }
    if (breadcrumb.filter.ext_code) {
      filter += '&ext_code=' + breadcrumb.filter.ext_code;
    }
    if (breadcrumb.filter.secondary_customer) {
      filter += '&secondary_customer=' + breadcrumb.filter.secondary_customer;
    }
  }

  return filter;
};

export const getOrderOperationStat = (
  operations,
  breadcrumb,
  page,
  size,
  sortDirection,
  term,
  itemTypes,
) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const operationCodes = operations ? operations.join(',') : '';
  const _itemTypes =
    itemTypes && itemTypes.length > 0 ? itemTypes.join(',') : '';
  const filter = getBreadcrumbFilter(breadcrumb);
  const termStr = term ? term : '';
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/stat/order/${branchId}?operation_codes=${operationCodes}&item_types=${_itemTypes}&${filter}&page=${page}&size=${size}&sort_direction=${sortDirection}&term=${termStr}`,
    data: {},
  });
};
export const getParentOrderOperationStat = (
  operations,
  breadcrumb,
  page,
  size,
  sortDirection,
  filterTerm,
  itemTypes,
) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const operationCodes = operations ? operations.join(',') : '';
  const _itemTypes =
    itemTypes && itemTypes.length > 0 ? itemTypes.join(',') : '';
  const filter = getBreadcrumbFilter(breadcrumb);
  filterTerm = filterTerm ? filterTerm : '';
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/stat/parentorder/${branchId}?operation_codes=${operationCodes}&item_types=${_itemTypes}&${filter}&page=${page}&size=${size}&sort_direction=${sortDirection}&term=${filterTerm}`,
    data: {},
  });
};
export const getCustomerOrderOperationStat = (
  operations,
  page,
  size,
  sortDirection,
  filterTerm,
  itemTypes,
) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const operationCodes = operations ? operations.join(',') : '';
  const _itemTypes =
    itemTypes && itemTypes.length > 0 ? itemTypes.join(',') : '';
  filterTerm = filterTerm ? filterTerm : '';
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/stat/customer/${branchId}?operation_codes=${operationCodes}&item_types=${_itemTypes}&page=${page}&size=${size}&sort_direction=${sortDirection}&term=${filterTerm}`,
    data: {},
  });
};
export const getExtCodeOrderOperationStat = (
  operations,
  breadcrumb,
  page,
  size,
  sortDirection,
  filterTerm,
  itemTypes,
) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const operationCodes = operations ? operations.join(',') : '';
  const _itemTypes =
    itemTypes && itemTypes.length > 0 ? itemTypes.join(',') : '';
  const filter = getBreadcrumbFilter(breadcrumb);
  filterTerm = filterTerm ? filterTerm : '';
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/stat/extcode/${branchId}?operation_codes=${operationCodes}&item_types=${_itemTypes}&${filter}&page=${page}&size=${size}&sort_direction=${sortDirection}&term=${filterTerm}`,
    data: {},
  });
};
export const getSecondaryCustomerOrderOperationStat = (
  operations,
  breadcrumb,
  page,
  size,
  sortDirection,
  filterTerm,
  itemTypes,
) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const operationCodes = operations ? operations.join(',') : '';
  const _itemTypes =
    itemTypes && itemTypes.length > 0 ? itemTypes.join(',') : '';
  const filter = getBreadcrumbFilter(breadcrumb);
  filterTerm = filterTerm ? filterTerm : '';
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/stat/secondarycustomer/${branchId}?operation_codes=${operationCodes}&item_types=${_itemTypes}&${filter}&page=${page}&size=${size}&sort_direction=${sortDirection}&term=${filterTerm}`,
    data: {},
  });
};

export const getOrderStatItems = (operations, breadcrumb, itemTypes) => {
  const { user } = getState();
  const { org } = user || {};
  //const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const operationCodes = operations ? operations.join(',') : '';
  const _itemTypes =
    itemTypes && itemTypes.length > 0 ? itemTypes.join(',') : '';
  const filter = getBreadcrumbFilter(breadcrumb);
  if (filter === '') {
    return Promise.resolve(resolve => resolve({ status: 200, payload: [] }));
  }
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/stat/items/${breadcrumb.filter.stat_type}?operation_codes=${operationCodes}&item_types=${_itemTypes}&${filter}`,
    data: {},
  });
};

export const refreshOrderOperationStat = orderId => {
  const { user } = getState();
  const { org } = user || {};
  return service({
    method: 'PUT',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstat/refresh/${orderId}`,
    data: {},
  });
};

export const getOperationRuleVerification = filter => {
  const { user } = getState();
  const { org } = user || {};
  if (!filter.req_payload) {
    filter.req_payload = {};
  }
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationrule/filter`,
    data: filter,
  });
};

export const updateOperationRuleVerification = (rule, completed) => {
  const { user } = getState();
  const { org } = user || {};

  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationrule/update`,
    data: {
      req_payload: {
        uuid: rule.uuid,
        completed: completed === true,
      },
    },
  });
};

export const getProductionSummary = (group_columns, filter) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  if (
    !filter.operation_code ||
    filter.operation_code.length === 0 ||
    !branchId
  ) {
    return Promise.resolve(resolve => resolve({ status: 200, payload: [] }));
  }
  filter.branch_id = [branchId];
  filter.deleted = [false];
  const columnMap = {
    ITEM_CODE: ['item_code'],
    EDGE: ['edge'],
    POD: ['pod'],
    ORDER: [
      'order_id',
      'parent_order_id',
      'order_ref_code',
      'order_ext_code',
      'order_secondary_customer',
      'entity_id',
    ],
    PARENT_ORDER: ['parent_order_id', 'parent_order_ref_code', 'entity_id'],
    SECONDARY_CUSTOMER: ['order_secondary_customer'],
    CUSTOMER: ['entity_id'],
    EXT_REFERENCE: ['order_ext_code'],
  };
  const gColumns = [];
  group_columns.forEach(val => {
    const mappedVal = columnMap[`${val}`];
    if (mappedVal && mappedVal.length > 0) {
      mappedVal.forEach(m => {
        gColumns.push(m);
      });
    }
  });
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/prodsummary/filter`,
    data: {
      group_columns: gColumns || [],
      req_payload: filter || {},
    },
  });
};

export const splitOrderItem = (orderItemId, splitQty) => {
  const { user } = getState();
  const { org } = user || {};

  splitQty = splitQty.filter(val => val > 0);
  if (splitQty.length < 2) {
    return new Promise(resolve =>
      resolve({ status: 1000, err: { err: 'Not enough items to split' } }),
    );
  }

  return service({
    method: 'PUT',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/items/split/${orderItemId}`,
    data: {
      req_payload: splitQty,
    },
  });
};

export const trackTraceOfflineReq = reqPayload => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  reqPayload.branch_id = branchId;
  if (!reqPayload.scanned_on) {
    reqPayload.scanned_on = new Date();
  }
  reqPayload.scan_posted_on = new Date();
  const allowCtxMisMatch = `allow_ctx_mismatch=${
    reqPayload.allow_ctx_mismatch === true
  }`;
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/operationstatus/update/offline?${allowCtxMisMatch}`,
    data: {
      req_payload: reqPayload,
    },
  });
};

export const recalculateBoxStatus = orderId => {
  const user = getState().user;
  if (!user) {
    return new Promise((resolve, reject) => {
      reject(USER_NOT_FOUND);
    });
  }
  const { org } = user;

  return service({
    method: 'PUT',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/orderitemstatus/recalculatebox/${orderId}`,
    data: {},
  });
};

export const checkPermission = (modulename, permission) => {
  const { user } = getState();
  const userOrgUuid = user.org.uuid;
  const userBranchId = user.branch.branch_id;
  const orgAccess = user.access?.[`${userOrgUuid}`];
  if (!orgAccess) return false;
  const branchAccess = orgAccess.find(
    branch => branch.branch_id === userBranchId,
  );
  if (!branchAccess) {
    return false;
  }
  const moduleAccess = branchAccess.module_access;
  const mainObject = moduleAccess?.[`${modulename}`];
  if (!mainObject) {
    return false;
  }
  const permissionObject = mainObject?.[`${permission}`];
  return !!permissionObject;
};
export const inactiveOrder = (uuid, isActive = false) => {
  const { user } = getState();
  const { org, user_id } = user || {};
  return service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/create`,
    data: {
      user_id,
      req_payload: {
        uuid: uuid,
        active: isActive,
      },
    },
  });
};

export const orgDataSize = () => {
  const { user } = getState();
  const { org, user_id } = user || {};
  return service({
    method: 'GET',
    url: `/naadi-app/api/naadi-org-service2/v1/naadi/org2/${org.uuid}/size-metric`,
    data: {
      user_id,
      req_payload: {},
    },
  });
};

export const orderDelete = uuid => {
  const { user } = getState();
  const { org, user_id } = user || {};
  return service({
    method: 'DELETE',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/cascade/${uuid}`,
    data: {
      user_id,
      req_payload: {},
    },
  });
};
