import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';
import PatternHeader from './pattern-header';
import PatternSubHeader from './pattern-sub-header';
import { getPrinters } from '../../order/labelprint/PrinterSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  toastError,
  toastSuccess,
} from '../../../helpers/packing/packingHelper';
import PatternPrintAllModal, {
  getPartsFromPattern,
} from './print-all-selector';
import {
  getNormalizedPattern,
  getPartCutSequence,
  patternPartScan,
} from '../../../helpers/cutguide/cutGuideHelper';
import {
  getBulkOperationStatusErrMsg,
  printOrderItemLabels,
} from '../../../services/order';
import {
  mergePdfFiles,
  toastResponseError,
} from '../../../helpers/common/helper';
import FileSaver from 'file-saver';
import { actions } from '@naadi/framework';
import { PatternContent, PatternContentFooter } from './pattern-content';
import { cloneDeep } from 'lodash';
import RejectReasonModal from '../../../components/tracktrace/reject-reason';
import { getRejectionReasons } from '../../../helpers/tracktrace/orderItemHelper';
import { printOffCutLabels } from '../../../services/cutsmart';

const MATERIALS = 'MATERIALS';
const MAT = 'MAT';
const CutGuidePattern = ({
  current_station,
  wo,
  woResult,
  matId,
  patternId,
  onPartStatusUpdate,
  displayTemplate,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [woMat, setWoMat] = useState({});
  const [woPattern, setWoPattern] = useState({});
  // eslint-disable-next-line
  const [ctx, setCtx] = useState({
    type: {
      label: MATERIALS,
      value: MAT,
    },
    value: null,
  });
  const [printers, setPrinters] = useState([]);
  const { printer } = useSelector(state => state.cutguide);
  const [selectedPrinter, setSelectedPrinter] = useState({});
  const [printAllModal, setPrintAllModal] = useState({ show: false });
  const [currentPart, setCurrentPart] = useState({});
  const [cutParts, setCutParts] = useState([]);
  const { pattern_large } = useSelector(state => state.cgstationstate);
  const [normalizedPattern, setNormalizedPattern] = useState(
    getNormalizedPattern(woPattern),
  );
  const [showRejectModal, setShowRejectModal] = useState({
    show: false,
    scanCode: '',
    manualEntry: false,
    rejectReasons: ['Other'],
  });
  useEffect(() => {
    const fn = async () => {
      const p = await getPrinters(dispatch);
      const mappedPrinters = p.map(val => {
        if (
          printer &&
          printer.printer_id === val.printer_id &&
          val.org_id === printer.org_id
        ) {
          val.selected = true;
        } else {
          val.selected = false;
        }
        return val;
      });
      setPrinters(mappedPrinters);
      setSelectedPrinter(mappedPrinters.filter(val => val.selected)[0] || {});
    };
    fn();
  }, [dispatch, setPrinters, printer, setSelectedPrinter]);
  useEffect(() => {
    if (!woResult || !woResult.wo_optimization_materials || !matId) {
      setWoMat({});
      return;
    }
    const woMatList = woResult.wo_optimization_materials.filter(
      val => val.uuid === matId,
    );
    if (!woMatList || woMatList.length === 0) {
      setWoMat({});
      return;
    }
    const woMat = woMatList[0];
    setWoMat(woMat);
  }, [matId, woResult, setWoMat]);
  useEffect(() => {
    if (printer && printer.printer_id && currentPart?.wo_item?.order_item_id) {
      printOrderItemLabels({
        order_item_id: [currentPart?.wo_item?.order_item_id],
        printer_id: printer.printer_id,
        batch_size: 2,
        dry_run: true,
      });
    }
  }, [currentPart, printer]);
  const warmUpPrintAllLabels = useCallback(() => {
    if (printer && printer.printer_id && woPattern) {
      const allParts = getPartsFromPattern(woPattern);
      for (let i = 0; i < allParts.length; i++) {
        const part = allParts[`${i}`];
        if (part.type === 'PART' && part?.wo_item?.order_item_id) {
          printOrderItemLabels({
            order_item_id: [part?.wo_item?.order_item_id],
            printer_id: printer.printer_id,
            batch_size: 2,
            dry_run: true,
          });
        }
      }
    }
  }, [printer, woPattern]);
  useEffect(() => {
    if (!woMat || !woMat.patterns || woMat.patterns.length === 0) {
      setWoPattern({});
      return;
    }
    const filteredPattern = woMat.patterns.filter(
      val => val.uuid === patternId,
    )[0];
    setWoPattern(cloneDeep(filteredPattern || {}));
  }, [patternId, woMat, setWoPattern]);
  useEffect(() => {
    if (!woMat.id) {
      setCtx({
        type: {
          label: MATERIALS,
          value: MAT,
        },
        value: null,
      });
    } else {
      setCtx({
        type: {
          label: MATERIALS,
          value: MAT,
        },
        value: {
          label: woMat.material,
          value: woMat.uuid,
        },
      });
    }
  }, [woMat]);
  useEffect(() => {
    if (!woPattern.uuid) {
      return;
    }
    setNormalizedPattern(getNormalizedPattern(woPattern));
  }, [woPattern]);
  useEffect(() => {
    setCurrentPart(currentPart => {
      const partMatch = cutParts.filter(
        val => val.uuid === currentPart.uuid,
      )[0];
      if (partMatch) {
        return currentPart;
      }
      for (let i = 0; i < cutParts.length; i++) {
        const part = cutParts[`${i}`];
        if (part.type === 'PART' && part.processed_qty === 0) {
          return { ...part };
        }
      }
      return {};
    });
  }, [cutParts, setCurrentPart]);
  useEffect(() => {
    if (!normalizedPattern.uuid) {
      return;
    }
    //const kerf = woMat.kerf || 4.0;
    const parts = [...normalizedPattern.parts];
    const remnants = [
      ...normalizedPattern.remnants.filter(
        val =>
          (val.length > 80 && val.width > 80) ||
          (val.length * val.width) / (1000.0 * 1000.0) > 0.11,
      ),
    ];
    parts.forEach(val => {
      val.type = 'PART';
    });
    remnants.forEach(val => {
      val.type = 'REMNANT';
    });
    const sortFn = (a, b) => {
      if (a.y === b.y) {
        return a.x - b.x;
      }
      return a.y - b.y;
    };

    const cuts = getPartCutSequence(
      0,
      normalizedPattern.pattern_cuts,
      normalizedPattern,
      parts,
      remnants,
    );
    let orderedParts = [];
    cuts.forEach(cut => {
      cut.parts.sort(sortFn);
      orderedParts = [...orderedParts, ...cut.parts];
    });
    setCutParts(orderedParts);
  }, [woMat, normalizedPattern]);
  const onScanParts = useCallback(
    async (pattern, parts) => {
      if (parts.length === 0) {
        return { success: [], errs: [], print_images: {}, print_errors: {} };
      }
      const resp = await patternPartScan(
        parts.map(part => part.wo_item.order_item_id),
        true,
        { reasons: [], comment: '', rejected: false },
        [],
        dispatch,
        current_station,
        parts.length <= 1 ? selectedPrinter?.printer_id : null,
      );
      if (resp.errs && resp.errs.length > 0) {
        const errMsg = getBulkOperationStatusErrMsg(resp.errs);
        toastError(errMsg);
        return { errs: resp.errs, print_images: {}, print_errors: {} };
      }
      if (resp.success === false) {
        toastError(
          'Unable to update the item status at this time. Please retry',
        );
        return { success: [], errs: [], print_images: {}, print_errors: {} };
      }
      return resp.payload.length === 0 ? null : resp.payload;
    },
    [dispatch, current_station, selectedPrinter],
  );
  /* eslint-disable sonarjs/cognitive-complexity */
  const onPartLabelPrint = useCallback(
    async (orderItemIds, fileSuffix, printImageMap, printErrMap) => {
      const reqData = {
        order_item_id: [],
        printer_id: selectedPrinter.printer_id,
        batch_size: orderItemIds.length + 1,
      };
      if (selectedPrinter.printer_id === 'OFFLINE_PDF_PRINTER') {
        reqData.file_name = 'Part_Labels.pdf';
      }
      const images = [];
      for (let i = 0; i < orderItemIds.length; i++) {
        const oi = orderItemIds[`${i}`];
        if (printErrMap[`${oi}`]) {
          const printErr = printErrMap[`${oi}`];
          const printErrMsg = printErr?.err?.err
            ? printErr?.err?.err
            : printErr?.err
              ? printErr?.err
              : printErr + '';
          toastError(printErrMsg);
          return {
            printed: false,
            err_msg: printErrMsg,
          };
        } else if (printImageMap[`${oi}`]) {
          const printImages = printImageMap[`${oi}`];
          printImages.forEach(im => {
            images.push(im);
          });
        } else {
          reqData.order_item_id.push(oi);
        }
      }

      if (reqData.order_item_id.length > 0) {
        const printLabelResp = await printOrderItemLabels(reqData);
        if (printLabelResp.status !== 200) {
          toastResponseError(printLabelResp, 'Unable to Print Label');
          return {
            printed: false,
            err_msg:
              printLabelResp.err && printLabelResp.err.err
                ? printLabelResp.err.err
                : 'Unable to print Label',
          };
        }
        printLabelResp.payload.images.forEach(im => {
          images.push(im);
        });
      }

      //const images = printLabelResp.payload.images;
      if (
        selectedPrinter.printer_id === 'OFFLINE_PDF_PRINTER' &&
        images.length > 0
      ) {
        let url = images[0];
        if (images.length > 1) {
          url = await mergePdfFiles(images);
        }
        FileSaver.saveAs(
          url,
          'Part_Labels' + (fileSuffix ? '_' + fileSuffix : '') + '.pdf',
        );
      }
      return {
        printed: true,
      };
    },
    [selectedPrinter],
  );
  /* eslint-enable sonarjs/cognitive-complexity */

  const onOffCutLabelPrint = useCallback(
    async (offCutIds, fileSuffix) => {
      if (offCutIds.length === 0) {
        return {
          printed: true,
        };
      }
      let fileName = '';
      let format = 'PNG';
      if (selectedPrinter.printer_id === 'OFFLINE_PDF_PRINTER') {
        fileName = 'Offcut_Labels.pdf';
        format = 'PDF';
      }
      const printLabelResp = await printOffCutLabels(
        offCutIds,
        selectedPrinter.printer_id,
        format,
        fileName,
        offCutIds.length,
      );
      if (printLabelResp.status !== 200) {
        toastResponseError(printLabelResp, 'Unable to Print Label');
        return {
          printed: false,
          err_msg:
            printLabelResp.err && printLabelResp.err.err
              ? printLabelResp.err.err
              : 'Unable to print Label',
        };
      }
      const images = printLabelResp.payload.images;
      if (images && selectedPrinter.printer_id === 'OFFLINE_PDF_PRINTER') {
        for (let i = 0; i < images.length; i++) {
          FileSaver.saveAs(
            images[`${i}`],
            'Offcut_Labels' + (fileSuffix ? '_' + fileSuffix : '') + '.pdf',
          );
        }
      }
      return {
        printed: true,
        errs: [],
      };
    },
    [selectedPrinter],
  );
  const onPartsPrint = useCallback(
    async (parts, woPattern) => {
      try {
        dispatch(actions.busy.add('PRINT_PARTS'));
        let printed = [];
        const scannedParts = await onScanParts(
          woPattern,
          parts.filter(val => val.type === 'PART'),
        );

        if (scannedParts.errs && scannedParts.errs.length > 0) {
          toastError(
            'Unable to scan ' +
              scannedParts.errs.length +
              ' Items from the list, ' +
              getBulkOperationStatusErrMsg(scannedParts.errs),
          );
          return;
        }
        const scannedPartsItemId = scannedParts.success.map(
          val => val.order_item_id,
        );
        const processed = parts.filter(
          val =>
            val.type === 'PART' &&
            scannedPartsItemId.indexOf(val.wo_item.order_item_id) >= 0,
        );
        if (processed.length > 0) {
          const orderItemIds = processed.map(val => val.wo_item.order_item_id);
          const printedState = await onPartLabelPrint(
            orderItemIds,
            woPattern.uuid,
            scannedParts.print_images || {},
            scannedParts.print_errors || {},
          );
          if (printedState.printed === true) {
            printed = processed;
          } else {
            toastError(printedState.err_msg);
          }
          onPartStatusUpdate(processed, false);
        }
        const offCutIds = parts
          .filter(val => val.type === 'REMNANT')
          .map(val => val.uuid);
        if (offCutIds.length > 0 && printed) {
          const printedState = await onOffCutLabelPrint(
            offCutIds,
            woPattern.uuid,
          );
          if (printedState.printed === true) {
            printed = processed;
          } else {
            toastError(printedState.err_msg);
          }
        }
        /*if (printed.length < processed.length && parts.length > 1) {
          toastWarning(
            printed.length + ' of ' + processed.length + ' Labels printed'
          );
        }*/
        return printed;
      } finally {
        dispatch(actions.busy.remove('PRINT_PARTS'));
      }
    },
    [
      dispatch,
      onScanParts,
      onPartLabelPrint,
      onOffCutLabelPrint,
      onPartStatusUpdate,
    ],
  );
  const onNextPartSelect = useCallback(() => {
    if (!cutParts || cutParts.length === 0) {
      return;
    }
    const nextCutParts = cutParts.filter(val => val.type === 'PART');
    if (nextCutParts.length === 0) {
      return;
    }
    setCurrentPart(cp => {
      for (let i = 0; i < nextCutParts.length - 1; i++) {
        const p = nextCutParts[`${i}`];
        if (p.uuid === cp.uuid) {
          return nextCutParts[`${i + 1}`];
        }
      }
      return cp;
    });
  }, [cutParts, setCurrentPart]);

  const onPrintAndNext = useCallback(
    async currentPart => {
      if (!cutParts || cutParts.length === 0) {
        return;
      }
      if (!currentPart) {
        return;
      }
      if (currentPart.type !== 'PART' && currentPart.type !== 'REMNANT') {
        return;
      }
      const printed = await onPartsPrint([currentPart], woPattern);
      if (printed && printed.length > 0) {
        onNextPartSelect();
      }
    },
    [cutParts, onPartsPrint, woPattern, onNextPartSelect],
  );
  const onRejectModalClose = useCallback(() => {
    setShowRejectModal({
      show: false,
      scanCode: '',
      manualEntry: false,
      rejectReasons: [],
    });
  }, [setShowRejectModal]);
  const onRejectModalConfirm = useCallback(
    async (rejectreason, rejectcomment, images) => {
      const resp = await patternPartScan(
        [showRejectModal.scanCode],
        true,
        {
          rejected: true,
          reasons: rejectreason.length === 0 ? ['Other'] : rejectreason,
          comment: rejectcomment,
          rejectReasons: [],
        },
        images,
        dispatch,
        current_station,
      );

      if (resp.error) {
        toastError(resp.error);
        return null;
      }

      if (resp.success === false) {
        toastError(
          'Unable to update the item status at this time. Please retry',
        );
        return null;
      }
      toastSuccess('Part rejected');
      setShowRejectModal({
        show: false,
        scanCode: '',
        manualEntry: false,
        rejectReasons: ['Other'],
      });
      if (resp.success === true) {
        onPartStatusUpdate([showRejectModal.currentPart], true);
      }
    },
    [
      dispatch,
      showRejectModal,
      setShowRejectModal,
      current_station,
      onPartStatusUpdate,
    ],
  );
  const onBack = useCallback(() => {
    if (wo && wo.uuid && woMat && woMat.uuid) {
      history.push(
        '/app/cutguide/materials/' +
          wo.org_id +
          '/' +
          wo.uuid +
          '/pattern/' +
          woMat.uuid,
      );
    } else {
      history.push('/app/cutguide/home');
    }
  }, [wo, history, woMat]);
  return (
    <Fragment>
      <div style={{ height: '100vh' }}>
        <PatternHeader
          currentStation={current_station}
          location={location}
          history={history}
          onBack={onBack}
          label={'Patterns'}
          onPrintAllCb={() => {
            if (!selectedPrinter || !selectedPrinter.printer_id) {
              toastError('Select a printer first');
              return;
            }
            warmUpPrintAllLabels();
            setPrintAllModal({ show: true });
          }}
        />
        {pattern_large && (
          <PatternSubHeader
            woMat={woMat}
            pattern={woPattern}
            printers={printers}
            selectedPrinter={selectedPrinter}
          />
        )}
        <PatternContent
          normalizedPattern={normalizedPattern}
          woMat={woMat}
          optimization={woResult}
          currentPart={currentPart}
          cutParts={cutParts}
          setCurrentPart={setCurrentPart}
          onPrintAndNext={onPrintAndNext}
          pattern_large={pattern_large}
          displayTemplate={displayTemplate}
        />
        <PatternContentFooter
          currentPart={currentPart}
          cutParts={cutParts}
          next={onNextPartSelect}
          print={() => onPrintAndNext(currentPart)}
          onBack={onBack}
          onReject={async () => {
            if (
              !currentPart ||
              !currentPart.wo_item ||
              !currentPart.wo_item.order_item_id
            ) {
              return;
            }
            const rejectReasonsResp = await getRejectionReasons(
              null,
              currentPart.wo_item.order_item_id,
              current_station,
            );
            if (rejectReasonsResp.error) {
              toastError(rejectReasonsResp.error);
              return;
            }
            const rejectReasons = rejectReasonsResp.reasons;
            setShowRejectModal({
              show: true,
              scanCode: currentPart.wo_item.order_item_id,
              manualEntry: true,
              rejectReasons: rejectReasons,
              currentPart: currentPart,
            });
          }}
        />
        {printAllModal.show && (
          <PatternPrintAllModal
            pattern={woPattern}
            onCloseCb={() => {
              setPrintAllModal({ show: false });
            }}
            onPartPrint={parts => onPartsPrint(parts, woPattern)}
          />
        )}
        {showRejectModal.show && (
          <RejectReasonModal
            showModal={showRejectModal.show}
            onCloseCb={onRejectModalClose}
            onConfirmCb={onRejectModalConfirm}
            rejectReasons={showRejectModal.rejectReasons || []}
            scanCode={showRejectModal.scanCode}
          />
        )}
      </div>
    </Fragment>
  );
};

export default CutGuidePattern;

CutGuidePattern.propTypes = {
  mode: PropTypes.string,
};
